import ApiCaller from '../helpers/apiCaller';
import { BASE_URL } from '../config';
import convertJsonToFormData from '../helpers/convertJsonToFormData';

export const getProfileInfoService = () => {
	return ApiCaller({ needToken: true }).get(`${BASE_URL}api/core/business/profile/me`);
};

export const putProfileInfoService = postData => {
	return ApiCaller({ needToken: true }).put(`${BASE_URL}api/core/business/profile/me`, postData);
};

export const postProfilePictureService = postData => {
	return ApiCaller({ needToken: true }).post(
		`${BASE_URL}api/core/business/profile/picture`,
		convertJsonToFormData(postData),
	);
};

export const changePasswordService = postData => {
	return ApiCaller({ needToken: true }).post(`${BASE_URL}api/core/business/profile/change-password`, postData);
};

export const getResidencyStatusListService = () => {
	return ApiCaller({ needToken: true }).get(`${BASE_URL}api/core/general/utils/residency`);
};

export const getGenderListService = () => {
	return ApiCaller({ needToken: true }).get(`${BASE_URL}api/core/general/utils/gender`);
};

export const getSpecialGroupListService = () => {
	return ApiCaller({ needToken: true }).get(`${BASE_URL}api/core/general/utils/special-groups`);
};
