import React from 'react';
import { CFooter } from '@coreui/react';

const AppFooter = () => {
	return (
		<CFooter>
			<div>
				<span className="ms-1" style={{ color: 'black', fontSize: 12 }}>
					Powered by
					<a
						className="mx-1"
						style={{ color: 'black', fontSize: 12, textDecoration: 'none' }}
						target="_blank"
						rel="noreferrer"
						href="https://softversetech.com/"
					>
						Softverse Technology Inc.
					</a>
				</span>
			</div>
		</CFooter>
	);
};

export default React.memo(AppFooter);
