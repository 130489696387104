import * as actionTypes from '../constants/actionTypes';

const initialState = {
	allCountries: {
		loading: false,
		data: null,
	},
	allStateOfCountry: {
		loading: false,
		countryId: null,
		data: null,
	},
	allCitiesOfState: {
		loading: false,
		stateId: null,
		data: null,
	},
};

const General = (state = initialState, { type, payload }) => {
	switch (type) {
		case actionTypes.GET_ALL_COUNTRIES_REQUEST:
			return {
				...state,
				allCountries: { ...state.allCountries, loading: true },
			};
		case actionTypes.GET_ALL_COUNTRIES_SUCCESS:
			return {
				...state,
				allCountries: {
					loading: false,
					data: payload && payload.length > 0 && payload.map(el => ({ label: el.name, value: el.id })),
				},
			};
		case actionTypes.GET_ALL_COUNTRIES_FAILURE:
			return {
				...state,
				allCountries: { loading: false, data: null },
			};

		case actionTypes.GET_ALL_STATE_OF_COUNTRY_REQUEST:
			return {
				...state,
				allStateOfCountry: { ...state.allStateOfCountry, loading: true },
			};
		case actionTypes.GET_ALL_STATE_OF_COUNTRIES_SUCCESS:
			return {
				...state,
				allStateOfCountry: {
					loading: false,
					data: payload.data && payload.data.length > 0 && payload.data.map(el => ({ label: el.name, value: el.id })),
					countryId: payload.countryId,
				},
			};
		case actionTypes.GET_ALL_STATE_OF_COUNTRY_FAILURE:
			return {
				...state,
				allStateOfCountry: { loading: false, data: null, countryId: null },
			};

		case actionTypes.GET_ALL_CITIES_OF_STATE_REQUEST:
			return {
				...state,
				allCitiesOfState: { ...state.allCitiesOfState, loading: true },
			};
		case actionTypes.GET_ALL_CITIES_OF_STATE_SUCCESS:
			return {
				...state,
				allCitiesOfState: {
					loading: false,
					data: payload.data && payload.data.length > 0 && payload.data.map(el => ({ label: el.name, value: el.id })),
					stateId: payload.stateId,
				},
			};
		case actionTypes.GET_ALL_CITIES_OF_STATE_FAILURE:
			return {
				...state,
				allCitiesOfState: { loading: false, data: null, stateId: null },
			};

		default:
			return state;
	}
};

export default General;
