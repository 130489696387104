import React, { Suspense, useEffect } from 'react';
import { AppSidebar, AppFooter, AppHeader } from '../components';
import { CSpinner } from '@coreui/react';
import * as ProfileActions from '../../actions/profile';
import { connect } from 'react-redux';

const Index = props => {
	const { profileInfoData, getProfileInfo, clearData, children } = props;

	useEffect(() => {
		getProfileInfo();
	}, []);

	return (
		<div>
			<AppSidebar />
			<div className="wrapper d-flex flex-column min-vh-100 bg-light">
				<AppHeader profileSrc={profileInfoData?.profilePicture} />
				<div className="body flex-grow-1 px-3">
					<Suspense fallback={<CSpinner color="primary" />}>
						<div style={{ maxWidth: 1231.5, margin: 'auto' }}>{children}</div>
					</Suspense>
				</div>
				<AppFooter />
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		profileInfoData: state.profile.profileInfo.data,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getProfileInfo: () => dispatch(ProfileActions.getProfileInfo()),
		clearData: () => {
			dispatch(ProfileActions.getProfileInfoFailure());
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
