import * as actionTypes from '../constants/actionTypes';

const initialState = {
	allNotificationSettings: {
		loading: false,
		data: null,
	},
	putAllNotificationSettings: {
		loading: false,
		data: null,
	},
	sentNotifications: {
		loading: false,
		data: null,
	},
	sendSmsNotificationSingle: {
		loading: false,
		data: null,
	},
	sendSmsNotificationToFilteredUsers: {
		loading: false,
		data: null,
	},
	sendSmsNotificationToCourseUsers: {
		loading: false,
		data: null,
	},
	sendNotificationPageData: {
		type: null, // single || list
		users: null,
		total: 0,
		courseId: null,
		filter: null,
		userId: null,
	},
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case actionTypes.GET_ALL_NOTIFICATION_SETTINGS_REQUEST:
			return {
				...state,
				allNotificationSettings: { ...state.allNotificationSettings, loading: true },
			};
		case actionTypes.GET_ALL_NOTIFICATION_SETTINGS_SUCCESS:
			return {
				...state,
				allNotificationSettings: { loading: false, data: payload },
			};
		case actionTypes.GET_ALL_NOTIFICATION_SETTINGS_FAILURE:
			return {
				...state,
				allNotificationSettings: { loading: false, data: null },
			};

		case actionTypes.PUT_ALL_NOTIFICATION_SETTINGS_REQUEST:
			return {
				...state,
				putAllNotificationSettings: { ...state.putAllNotificationSettings, loading: true },
			};
		case actionTypes.PUT_ALL_NOTIFICATION_SETTINGS_SUCCESS:
			return {
				...state,
				putAllNotificationSettings: { loading: false, data: payload },
			};
		case actionTypes.PUT_ALL_NOTIFICATION_SETTINGS_FAILURE:
			return {
				...state,
				putAllNotificationSettings: { loading: false, data: null },
			};

		case actionTypes.GET_SENT_NOTIFICATIONS_REQUEST:
			return {
				...state,
				sentNotifications: { ...state.sentNotifications, loading: true },
			};
		case actionTypes.GET_SENT_NOTIFICATIONS_SUCCESS:
			return {
				...state,
				sentNotifications: { loading: false, data: payload },
			};
		case actionTypes.GET_SENT_NOTIFICATIONS_FAILURE:
			return {
				...state,
				sentNotifications: { loading: false, data: null },
			};

		case actionTypes.SEND_SMS_NOTIFICATION_SINGLE_REQUEST:
			return {
				...state,
				sendSmsNotificationSingle: { ...state.sendSmsNotificationSingle, loading: true },
			};
		case actionTypes.SEND_SMS_NOTIFICATION_SINGLE_SUCCESS:
			return {
				...state,
				sendSmsNotificationSingle: { loading: false, data: payload },
			};
		case actionTypes.SEND_SMS_NOTIFICATION_SINGLE_FAILURE:
			return {
				...state,
				sendSmsNotificationSingle: { loading: false, data: null },
			};

		case actionTypes.SEND_SMS_NOTIFICATION_TO_FILTERED_USERS_REQUEST:
			return {
				...state,
				sendSmsNotificationToFilteredUsers: { ...state.sendSmsNotificationToFilteredUsers, loading: true },
			};
		case actionTypes.SEND_SMS_NOTIFICATION_TO_FILTERED_USERS_SUCCESS:
			return {
				...state,
				sendSmsNotificationToFilteredUsers: { loading: false, data: payload },
			};
		case actionTypes.SEND_SMS_NOTIFICATION_TO_FILTERED_USERS_FAILURE:
			return {
				...state,
				sendSmsNotificationToFilteredUsers: { loading: false, data: null },
			};

		case actionTypes.SEND_SMS_NOTIFICATION_TO_COURSE_USERS_REQUEST:
			return {
				...state,
				sendSmsNotificationToCourseUsers: { ...state.sendSmsNotificationToCourseUsers, loading: true },
			};
		case actionTypes.SEND_SMS_NOTIFICATION_TO_COURSE_USERS_SUCCESS:
			return {
				...state,
				sendSmsNotificationToCourseUsers: { loading: false, data: payload },
			};
		case actionTypes.SEND_SMS_NOTIFICATION_TO_COURSE_USERS_FAILURE:
			return {
				...state,
				sendSmsNotificationToCourseUsers: { loading: false, data: null },
			};

		case actionTypes.SET_SEND_NOTIFICATION_PAGE_DATA:
			return {
				...state,
				sendNotificationPageData: payload
					? { ...payload }
					: {
							type: null,
							users: null,
							total: 0,
							courseId: null,
							filter: null,
							userId: null,
					  },
			};

		default:
			return state;
	}
};
