import React, { Suspense } from 'react';
import { CSpinner } from '@coreui/react';

const Index = props => {
	const { children } = props;
	return (
		<div className="wrapper d-flex flex-column min-vh-100 bg-light">
			<Suspense fallback={<CSpinner color="primary" />}>{children}</Suspense>
		</div>
	);
};

export default Index;
