import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
	CContainer,
	CHeader,
	CHeaderBrand,
	CHeaderDivider,
	CHeaderNav,
	CHeaderToggler,
	CNavLink,
	CNavItem,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cilBell, cilEnvelopeOpen, cilList, cilMenu } from '@coreui/icons';

import { AppBreadcrumb } from './index';
import { AppHeaderDropdown } from './header';
import { logo } from 'src/assets/brand/logo';

const AppHeader = ({ profileSrc }) => {
	const dispatch = useDispatch();
	const sidebarShow = useSelector(state => state.global.sidebarShow);

	return (
		<CHeader position="sticky" className="mb-4">
			<CContainer fluid>
				<CHeaderToggler
					className="ps-1"
					onClick={() => dispatch({ type: 'set', sidebarShow: !sidebarShow })}
					style={{ color: 'white' }}
				>
					<CIcon icon={cilMenu} size="lg" />
				</CHeaderToggler>
				<CHeaderNav className="d-none d-md-flex me-auto">
					<AppBreadcrumb />
				</CHeaderNav>
				{/*<CHeaderNav>*/}
				{/*	<CNavItem>*/}
				{/*		<CNavLink href="#" style={{ color: 'white' }}>*/}
				{/*			<CIcon icon={cilBell} size="lg" />*/}
				{/*		</CNavLink>*/}
				{/*	</CNavItem>*/}
				{/*	<CNavItem>*/}
				{/*		<CNavLink href="#" style={{ color: 'white' }}>*/}
				{/*			<CIcon icon={cilList} size="lg" />*/}
				{/*		</CNavLink>*/}
				{/*	</CNavItem>*/}
				{/*	<CNavItem>*/}
				{/*		<CNavLink href="#" style={{ color: 'white' }}>*/}
				{/*			<CIcon icon={cilEnvelopeOpen} size="lg" />*/}
				{/*		</CNavLink>*/}
				{/*	</CNavItem>*/}
				{/*</CHeaderNav>*/}
				<CHeaderNav className="ms-3" style={{ color: 'white' }}>
					<AppHeaderDropdown profileSrc={profileSrc} />
				</CHeaderNav>
			</CContainer>
		</CHeader>
	);
};

export default AppHeader;
