import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CImage, CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler } from '@coreui/react';
import { AppSidebarNav } from './AppSidebarNav';
import SimpleBar from 'simplebar-react';
import navigation from '../../routing/_nav';
import logo from '../../assets/images/svg/full_logo.svg';
import 'simplebar/dist/simplebar.min.css';

const AppSidebar = () => {
	const dispatch = useDispatch();
	const unfoldable = useSelector(state => state.global.sidebarUnfoldable);
	const sidebarShow = useSelector(state => state.global.sidebarShow);

	return (
		<CSidebar
			position="fixed"
			unfoldable={unfoldable}
			visible={sidebarShow}
			onVisibleChange={visible => {
				dispatch({ type: 'set', sidebarShow: visible });
			}}
		>
			<CSidebarBrand className="d-none d-md-flex px-4 justify-content-start" to="/">
				{/*<CIcon className="sidebar-brand-full" icon={logoNegative} height={35} />*/}
				{/*<CIcon className="sidebar-brand-narrow" icon={sygnet} height={35} />*/}
				<CImage src={logo} width={105} />
			</CSidebarBrand>
			<CSidebarNav>
				<SimpleBar>
					<AppSidebarNav items={navigation} isCollapsed={unfoldable} />
				</SimpleBar>
			</CSidebarNav>
			<CSidebarToggler
				className="d-none d-lg-flex"
				onClick={() => dispatch({ type: 'set', sidebarShow: !sidebarShow })}
			/>
		</CSidebar>
	);
};

export default React.memo(AppSidebar);
