/**
 * All web local storage methode.
 */

/**
 * Set item to local storage with expireTime.
 * @params {string, any, timestamp}
 * @example setLocalStorageWithExpiry("testKeyName", "testValue", 1)
 */
export const setLocalStorageWithExpiry = (key, value, ttl) => {
	const now = new Date();
	// `item` is an object which contains the original value
	// as well as the time when it's supposed to expire
	const item = {
		value: value,
		expiry: now.getTime() + ttl,
	};
	setLocalStorage(key, item);
};

/**
 * Get item from local storage with expireTime.
 * @params {string}
 * @example const value = getLocalStorageWithExpiry("testKeyName")
 */
export const getLocalStorageWithExpiry = key => {
	const item = getLocalStorage(key);
	if (!item) {
		return null;
	}
	const now = new Date();
	// compare the expiry time of the item with the current time
	if (now.getTime() > item.expiry) {
		// If the item is expired, delete the item from storage
		// and return null
		clearLocalStorage(key);
		return null;
	}
	return item.value;
};

/**
 * Set item to local storage.
 * @params {string, any}
 * @example setLocalStorage("testKeyName", "testValue")
 */
export const setLocalStorage = (key, value) => {
	if (!key) {
		throw 'key is required!';
	} else {
		localStorage.setItem(key, JSON.stringify(value));
	}
};

/**
 * Get item from local storage.
 * @params {string}
 * @example const value = setLocalStorage("testKeyName")
 */
export const getLocalStorage = key => {
	const itemString = localStorage.getItem(key);
	if (!itemString) {
		return null;
	} else {
		return JSON.parse(localStorage.getItem(key));
	}
};

/**
 * Remove item from local storage.
 * @params {string}
 * @example clearLocalStorage("testKeyName")
 */
export const clearLocalStorage = (key = null) => {
	if (!key) {
		localStorage.clear();
	} else {
		localStorage.removeItem(key);
	}
};
