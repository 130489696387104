import * as actionTypes from '../constants/actionTypes';

const initialState = {
	profileInfo: {
		loading: false,
		data: null,
	},
	putProfileInfo: {
		loading: false,
		data: null,
	},
	postProfilePicture: {
		loading: false,
		data: null,
	},
	changePassword: {
		loading: false,
		data: null,
	},
	residencyStatusList: {
		loading: false,
		data: null,
	},
	genderList: {
		loading: false,
		data: null,
	},
	specialGroupList: {
		loading: false,
		data: null,
	},
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case actionTypes.GET_PROFILE_INFO_REQUEST:
			return {
				...state,
				profileInfo: { ...state.profileInfo, loading: true },
			};
		case actionTypes.GET_PROFILE_INFO_SUCCESS:
			return {
				...state,
				profileInfo: { loading: false, data: payload },
			};
		case actionTypes.GET_PROFILE_INFO_FAILURE:
			return {
				...state,
				profileInfo: { loading: false, data: null },
			};

		case actionTypes.PUT_PROFILE_INFO_REQUEST:
			return {
				...state,
				putProfileInfo: { ...state.putProfileInfo, loading: true },
			};
		case actionTypes.PUT_PROFILE_INFO_SUCCESS:
			return {
				...state,
				putProfileInfo: { loading: false, data: payload },
			};
		case actionTypes.PUT_PROFILE_INFO_FAILURE:
			return {
				...state,
				putProfileInfo: { loading: false, data: null },
			};

		case actionTypes.POST_PROFILE_PICTURE_REQUEST:
			return {
				...state,
				postProfilePicture: { ...state.postProfilePicture, loading: true },
			};
		case actionTypes.POST_PROFILE_PICTURE_SUCCESS:
			return {
				...state,
				postProfilePicture: { loading: false, data: payload },
			};
		case actionTypes.POST_PROFILE_PICTURE_FAILURE:
			return {
				...state,
				postProfilePicture: { loading: false, data: null },
			};

		case actionTypes.CHANGE_PASSWORD_REQUEST:
			return {
				...state,
				changePassword: { ...state.changePassword, loading: true },
			};
		case actionTypes.CHANGE_PASSWORD_SUCCESS:
			return {
				...state,
				changePassword: { loading: false, data: payload },
			};
		case actionTypes.CHANGE_PASSWORD_FAILURE:
			return {
				...state,
				changePassword: { loading: false, data: null },
			};

		case actionTypes.GET_RESIDENCY_STATUS_LIST_REQUEST:
			return {
				...state,
				residencyStatusList: { ...state.residencyStatusList, loading: true },
			};
		case actionTypes.GET_RESIDENCY_STATUS_LIST_SUCCESS:
			return {
				...state,
				residencyStatusList: { loading: false, data: payload.map(el => ({ value: el.id, label: el.residencyStatus })) },
			};
		case actionTypes.GET_RESIDENCY_STATUS_LIST_FAILURE:
			return {
				...state,
				residencyStatusList: { loading: false, data: null },
			};

		case actionTypes.GET_GENDER_LIST_REQUEST:
			return {
				...state,
				genderList: { ...state.genderList, loading: true },
			};
		case actionTypes.GET_GENDER_LIST_SUCCESS:
			return {
				...state,
				genderList: { loading: false, data: payload.map(el => ({ value: el.id, label: el.gender })) },
			};
		case actionTypes.GET_GENDER_LIST_FAILURE:
			return {
				...state,
				genderList: { loading: false, data: null },
			};

		case actionTypes.GET_SPECIAL_GROUP_LIST_REQUEST:
			return {
				...state,
				specialGroupList: { ...state.specialGroupList, loading: true },
			};
		case actionTypes.GET_SPECIAL_GROUP_LIST_SUCCESS:
			return {
				...state,
				specialGroupList: { loading: false, data: payload.map(el => ({ value: el.id, label: el.groupName })) },
			};
		case actionTypes.GET_SPECIAL_GROUP_LIST_FAILURE:
			return {
				...state,
				specialGroupList: { loading: false, data: null },
			};

		default:
			return state;
	}
};
