// START general
export const GET_ALL_COUNTRIES_REQUEST = 'GET_ALL_COUNTRIES_REQUEST';
export const GET_ALL_COUNTRIES_SUCCESS = 'GET_ALL_COUNTRIES_SUCCESS';
export const GET_ALL_COUNTRIES_FAILURE = 'GET_ALL_COUNTRIES_FAILURE';

export const GET_ALL_STATE_OF_COUNTRY_REQUEST = 'GET_ALL_STATE_OF_COUNTRY_REQUEST';
export const GET_ALL_STATE_OF_COUNTRIES_SUCCESS = 'GET_ALL_STATE_OF_COUNTRY_SUCCESS';
export const GET_ALL_STATE_OF_COUNTRY_FAILURE = 'GET_ALL_STATE_OF_COUNTRY_FAILURE';

export const GET_ALL_CITIES_OF_STATE_REQUEST = 'GET_ALL_CITIES_OF_STATE_REQUEST';
export const GET_ALL_CITIES_OF_STATE_SUCCESS = 'GET_ALL_CITIES_OF_STATE_SUCCESS';
export const GET_ALL_CITIES_OF_STATE_FAILURE = 'GET_ALL_CITIES_OF_STATE_FAILURE';

// END general

// START auth
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
// END auth

// START user
export const GET_USER_STATUS_LIST_REQUEST = 'GET_USER_STATUS_LIST_REQUEST';
export const GET_USER_STATUS_LIST_SUCCESS = 'GET_USER_STATUS_LIST_SUCCESS';
export const GET_USER_STATUS_LIST_FAILURE = 'GET_USER_STATUS_LIST_FAILURE';

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';

export const GET_USER_LIST_REQUEST = 'GET_USER_LIST_REQUEST';
export const GET_USER_LIST_SUCCESS = 'GET_USER_LIST_SUCCESS';
export const GET_USER_LIST_FAILURE = 'GET_USER_LIST_FAILURE';

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';

export const CHANGE_USER_STATUS_REQUEST = 'CHANGE_USER_STATUS_REQUEST';
export const CHANGE_USER_STATUS_SUCCESS = 'CHANGE_USER_STATUS_SUCCESS';
export const CHANGE_USER_STATUS_FAILURE = 'CHANGE_USER_STATUS_FAILURE';

export const PUT_USER_REQUEST = 'PUT_USER_REQUEST';
export const PUT_USER_SUCCESS = 'PUT_USER_SUCCESS';
export const PUT_USER_FAILURE = 'PUT_USER_FAILURE';

export const SET_USERS_FILTER = 'SET_USERS_FILTER';

export const SET_INSTRUCTORS_FILTER = 'SET_INSTRUCTORS_FILTER';

export const GET_USER_ROLES_REQUEST = 'GET_USER_ROLES_REQUEST';
export const GET_USER_ROLES_SUCCESS = 'GET_USER_ROLES_SUCCESS';
export const GET_USER_ROLES_FAILURE = 'GET_USER_ROLES_FAILURE';

export const PUT_USER_ROLE_REQUEST = 'PUT_USER_ROLE_REQUEST';
export const PUT_USER_ROLE_SUCCESS = 'PUT_USER_ROLE_SUCCESS';
export const PUT_USER_ROLE_FAILURE = 'PUT_USER_ROLE_FAILURE';

export const DOWNLOAD_USERS_REQUEST = 'DOWNLOAD_USERS_REQUEST';
export const DOWNLOAD_USERS_SUCCESS = 'DOWNLOAD_USERS_SUCCESS';
export const DOWNLOAD_USERS_FAILURE = 'DOWNLOAD_USERS_FAILURE';

// END user

// START game
export const GET_ALL_GAME_TYPE_REQUEST = 'GET_ALL_GAME_TYPE_REQUEST';
export const GET_ALL_GAME_TYPE_SUCCESS = 'GET_ALL_GAME_TYPE_SUCCESS';
export const GET_ALL_GAME_TYPE_FAILURE = 'GET_ALL_GAME_TYPE_FAILURE';

export const GET_ALL_GAME_CONDITION_TYPE_REQUEST = 'GET_ALL_GAME_CONDITION_TYPE_REQUEST';
export const GET_ALL_GAME_CONDITION_TYPE_SUCCESS = 'GET_ALL_GAME_CONDITION_TYPE_SUCCESS';
export const GET_ALL_GAME_CONDITION_TYPE_FAILURE = 'GET_ALL_GAME_CONDITION_TYPE_FAILURE';

export const GET_ALL_GAME_REQUEST = 'GET_ALL_GAME_REQUEST';
export const GET_ALL_GAME_SUCCESS = 'GET_ALL_GAME_SUCCESS';
export const GET_ALL_GAME_FAILURE = 'GET_ALL_GAME_FAILURE';

export const PUT_ALL_GAME_REQUEST = 'PUT_ALL_GAME_REQUEST';
export const PUT_ALL_GAME_SUCCESS = 'PUT_ALL_GAME_SUCCESS';
export const PUT_ALL_GAME_FAILURE = 'PUT_ALL_GAME_FAILURE';

export const GET_BADGES_REQUEST = 'GET_BADGES_REQUEST';
export const GET_BADGES_SUCCESS = 'GET_BADGES_SUCCESS';
export const GET_BADGES_FAILURE = 'GET_BADGES_FAILURE';

export const POST_BADGE_REQUEST = 'POST_BADGE_REQUEST';
export const POST_BADGE_SUCCESS = 'POST_BADGE_SUCCESS';
export const POST_BADGE_FAILURE = 'POST_BADGE_FAILURE';

export const PUT_BADGE_REQUEST = 'PUT_BADGE_REQUEST';
export const PUT_BADGE_SUCCESS = 'PUT_BADGE_SUCCESS';
export const PUT_BADGE_FAILURE = 'PUT_BADGE_FAILURE';

export const DELETE_BADGE_REQUEST = 'DELETE_BADGE_REQUEST';
export const DELETE_BADGE_SUCCESS = 'DELETE_BADGE_SUCCESS';
export const DELETE_BADGE_FAILURE = 'DELETE_BADGE_FAILURE';

// END game

// START subject
export const GET_ALL_SUBJECTS_REQUEST = 'GET_ALL_SUBJECTS_REQUEST';
export const GET_ALL_SUBJECTS_SUCCESS = 'GET_ALL_SUBJECTS_SUCCESS';
export const GET_ALL_SUBJECTS_FAILURE = 'GET_ALL_SUBJECTS_FAILURE';

export const POST_SUBJECT_REQUEST = 'POST_SUBJECT_REQUEST';
export const POST_SUBJECT_SUCCESS = 'POST_SUBJECT_SUCCESS';
export const POST_SUBJECT_FAILURE = 'POST_SUBJECT_FAILURE';

export const PUT_SUBJECT_REQUEST = 'PUT_SUBJECT_REQUEST';
export const PUT_SUBJECT_SUCCESS = 'PUT_SUBJECT_SUCCESS';
export const PUT_SUBJECT_FAILURE = 'PUT_SUBJECT_FAILURE';

export const DELETE_SUBJECT_REQUEST = 'DELETE_SUBJECT_REQUEST';
export const DELETE_SUBJECT_SUCCESS = 'DELETE_SUBJECT_SUCCESS';
export const DELETE_SUBJECT_FAILURE = 'DELETE_SUBJECT_FAILURE';

export const GET_SUBJECT_BY_ID_REQUEST = 'GET_SUBJECT_BY_ID_REQUEST';
export const GET_SUBJECT_BY_ID_SUCCESS = 'GET_SUBJECT_BY_ID_SUCCESS';
export const GET_SUBJECT_BY_ID_FAILURE = 'GET_SUBJECT_BY_ID_FAILURE';
// END subject

// START course
export const GET_ALL_COURSES_REQUEST = 'GET_ALL_COURSES_REQUEST';
export const GET_ALL_COURSES_SUCCESS = 'GET_ALL_COURSES_SUCCESS';
export const GET_ALL_COURSES_FAILURE = 'GET_ALL_COURSES_FAILURE';

export const GET_COURSE_BY_ID_REQUEST = 'GET_COURSE_BY_ID_REQUEST';
export const GET_COURSE_BY_ID_SUCCESS = 'GET_COURSE_BY_ID_SUCCESS';
export const GET_COURSE_BY_ID_FAILURE = 'GET_COURSE_BY_ID_FAILURE';

export const GET_STUDENTS_OF_COURSE_REQUEST = 'GET_STUDENTS_OF_COURSE_REQUEST';
export const GET_STUDENTS_OF_COURSE_SUCCESS = 'GET_STUDENTS_OF_COURSE_SUCCESS';
export const GET_STUDENTS_OF_COURSE_FAILURE = 'GET_STUDENTS_OF_COURSE_FAILURE';

export const GET_VISITORS_METRICS_OF_COURSE_REQUEST = 'GET_VISITORS_METRICS_OF_COURSE_REQUEST';
export const GET_VISITORS_METRICS_OF_COURSE_SUCCESS = 'GET_VISITORS_METRICS_OF_COURSE_SUCCESS';
export const GET_VISITORS_METRICS_OF_COURSE_FAILURE = 'GET_VISITORS_METRICS_OF_COURSE_FAILURE';

export const GET_NEW_STUDENTS_METRICS_OF_COURSE_REQUEST = 'GET_NEW_STUDENTS_METRICS_OF_COURSE_REQUEST';
export const GET_NEW_STUDENTS_METRICS_OF_COURSE_SUCCESS = 'GET_NEW_STUDENTS_METRICS_OF_COURSE_SUCCESS';
export const GET_NEW_STUDENTS_METRICS_OF_COURSE_FAILURE = 'GET_NEW_STUDENTS_METRICS_OF_COURSE_FAILURE';

export const SET_COURSES_FILTER = 'SET_COURSES_FILTER';

export const PUT_COURSE_STATUS_REQUEST = 'PUT_COURSE_STATUS_REQUEST';
export const PUT_COURSE_STATUS_SUCCESS = 'PUT_COURSE_STATUS_SUCCESS';
export const PUT_COURSE_STATUS_FAILURE = 'PUT_COURSE_STATUS_FAILURE';

export const GET_ADMIN_ASSIGNMENT_ATTEMPTS_REQUEST = 'GET_ADMIN_ASSIGNMENT_ATTEMPTS_REQUEST';
export const GET_ADMIN_ASSIGNMENT_ATTEMPTS_SUCCESS = 'GET_ADMIN_ASSIGNMENT_ATTEMPTS_SUCCESS';
export const GET_ADMIN_ASSIGNMENT_ATTEMPTS_FAILURE = 'GET_ADMIN_ASSIGNMENT_ATTEMPTS_FAILURE';

export const SET_ADMIN_ASSIGNMENT_ATTEMPTS_FILTER = 'SET_ADMIN_ASSIGNMENT_ATTEMPTS_FILTER';

export const GET_ADMIN_ASSIGNMENT_ATTEMPT_BY_ID_REQUEST = 'GET_ADMIN_ASSIGNMENT_ATTEMPT_BY_ID_REQUEST';
export const GET_ADMIN_ASSIGNMENT_ATTEMPT_BY_ID_SUCCESS = 'GET_ADMIN_ASSIGNMENT_ATTEMPT_BY_ID_SUCCESS';
export const GET_ADMIN_ASSIGNMENT_ATTEMPT_BY_ID_FAILURE = 'GET_ADMIN_ASSIGNMENT_ATTEMPT_BY_ID_FAILURE';

export const ADMIN_GRADE_ASSIGNMENT_ATTEMPT_REQUEST = 'ADMIN_GRADE_ASSIGNMENT_ATTEMPT_REQUEST';
export const ADMIN_GRADE_ASSIGNMENT_ATTEMPT_SUCCESS = 'ADMIN_GRADE_ASSIGNMENT_ATTEMPT_SUCCESS';
export const ADMIN_GRADE_ASSIGNMENT_ATTEMPT_FAILURE = 'ADMIN_GRADE_ASSIGNMENT_ATTEMPT_FAILURE';

export const GET_ADMIN_QUIZ_ATTEMPTS_REQUEST = 'GET_ADMIN_QUIZ_ATTEMPTS_REQUEST';
export const GET_ADMIN_QUIZ_ATTEMPTS_SUCCESS = 'GET_ADMIN_QUIZ_ATTEMPTS_SUCCESS';
export const GET_ADMIN_QUIZ_ATTEMPTS_FAILURE = 'GET_ADMIN_QUIZ_ATTEMPTS_FAILURE';

// END course

// START journey
export const GET_ALL_JOURNEYS_REQUEST = 'GET_ALL_JOURNEYS_REQUEST';
export const GET_ALL_JOURNEYS_SUCCESS = 'GET_ALL_JOURNEYS_SUCCESS';
export const GET_ALL_JOURNEYS_FAILURE = 'GET_ALL_JOURNEYS_FAILURE';

export const GET_JOURNEY_BY_ID_REQUEST = 'GET_JOURNEY_BY_ID_REQUEST';
export const GET_JOURNEY_BY_ID_SUCCESS = 'GET_JOURNEY_BY_ID_SUCCESS';
export const GET_JOURNEY_BY_ID_FAILURE = 'GET_JOURNEY_BY_ID_FAILURE';

export const GET_STUDENTS_OF_JOURNEY_REQUEST = 'GET_STUDENTS_OF_JOURNEY_REQUEST';
export const GET_STUDENTS_OF_JOURNEY_SUCCESS = 'GET_STUDENTS_OF_JOURNEY_SUCCESS';
export const GET_STUDENTS_OF_JOURNEY_FAILURE = 'GET_STUDENTS_OF_JOURNEY_FAILURE';

export const GET_VISITORS_METRICS_OF_JOURNEY_REQUEST = 'GET_VISITORS_METRICS_OF_JOURNEY_REQUEST';
export const GET_VISITORS_METRICS_OF_JOURNEY_SUCCESS = 'GET_VISITORS_METRICS_OF_JOURNEY_SUCCESS';
export const GET_VISITORS_METRICS_OF_JOURNEY_FAILURE = 'GET_VISITORS_METRICS_OF_JOURNEY_FAILURE';

export const GET_NEW_STUDENTS_METRICS_OF_JOURNEY_REQUEST = 'GET_NEW_STUDENTS_METRICS_OF_JOURNEY_REQUEST';
export const GET_NEW_STUDENTS_METRICS_OF_JOURNEY_SUCCESS = 'GET_NEW_STUDENTS_METRICS_OF_JOURNEY_SUCCESS';
export const GET_NEW_STUDENTS_METRICS_OF_JOURNEY_FAILURE = 'GET_NEW_STUDENTS_METRICS_OF_JOURNEY_FAILURE';

export const SET_JOURNEYS_FILTER = 'SET_JOURNEYS_FILTER';

export const PUT_JOURNEY_STATUS_REQUEST = 'PUT_JOURNEY_STATUS_REQUEST';
export const PUT_JOURNEY_STATUS_SUCCESS = 'PUT_JOURNEY_STATUS_SUCCESS';
export const PUT_JOURNEY_STATUS_FAILURE = 'PUT_JOURNEY_STATUS_FAILURE';

// END journey

// START comment

export const GET_ALL_COMMENTS_REQUEST = 'GET_ALL_COMMENTS_REQUEST';
export const GET_ALL_COMMENTS_SUCCESS = 'GET_ALL_COMMENTS_SUCCESS';
export const GET_ALL_COMMENTS_FAILURE = 'GET_ALL_COMMENTS_FAILURE';

export const SET_COMMENTS_FILTER = 'SET_COMMENTS_FILTER';

export const GET_COMMENT_BY_ID_REQUEST = 'GET_COMMENT_BY_ID_REQUEST';
export const GET_COMMENT_BY_ID_SUCCESS = 'GET_COMMENT_BY_ID_SUCCESS';
export const GET_COMMENT_BY_ID_FAILURE = 'GET_COMMENT_BY_ID_FAILURE';

export const REPLY_COMMENT_BY_ADMIN_REQUEST = 'REPLY_COMMENT_BY_ADMIN_REQUEST';
export const REPLY_COMMENT_BY_ADMIN_SUCCESS = 'REPLY_COMMENT_BY_ADMIN_SUCCESS';
export const REPLY_COMMENT_BY_ADMIN_FAILURE = 'REPLY_COMMENT_BY_ADMIN_FAILURE';

export const DELETE_COMMENT_BY_ADMIN_REQUEST = 'DELETE_COMMENT_BY_ADMIN_REQUEST';
export const DELETE_COMMENT_BY_ADMIN_SUCCESS = 'DELETE_COMMENT_BY_ADMIN_SUCCESS';
export const DELETE_COMMENT_BY_ADMIN_FAILURE = 'DELETE_COMMENT_BY_ADMIN_FAILURE';

// END comment

// START library
export const GET_ALL_RESOURCES_LOADING = 'GET_ALL_RESOURCES_LOADING';
export const GET_ALL_RESOURCES_SUCCESS = 'GET_ALL_RESOURCES_SUCCESS';
export const GET_ALL_RESOURCES_FAILURE = 'GET_ALL_RESOURCES_FAILURE';

export const POST_RESOURCE_LOADING = 'POST_RESOURCE_LOADING';
export const POST_RESOURCE_SUCCESS = 'POST_RESOURCE_SUCCESS';
export const POST_RESOURCE_FAILURE = 'POST_RESOURCE_FAILURE';

export const GET_ADMIN_RESOURCES_LOADING = 'GET_ADMIN_RESOURCES_LOADING';
export const GET_ADMIN_RESOURCES_SUCCESS = 'GET_ADMIN_RESOURCES_SUCCESS';
export const GET_ADMIN_RESOURCES_FAILURE = 'GET_ADMIN_RESOURCES_FAILURE';

export const GET_RESOURCE_LOADING = 'GET_RESOURCE_LOADING';
export const GET_RESOURCE_SUCCESS = 'GET_RESOURCE_SUCCESS';
export const GET_RESOURCE_FAILURE = 'GET_RESOURCE_FAILURE';

export const PUT_RESOURCE_BY_ID_LOADING = 'PUT_RESOURCE_BY_ID_LOADING';
export const PUT_RESOURCE_BY_ID_SUCCESS = 'PUT_RESOURCE_BY_ID_SUCCESS';
export const PUT_RESOURCE_BY_ID_FAILURE = 'PUT_RESOURCE_BY_ID_FAILURE ';

export const DELETE_RESOURCE_BY_ID_LOADING = 'DELETE_RESOURCE_BY_ID_LOADING';
export const DELETE_RESOURCE_BY_ID_SUCCESS = 'DELETE_RESOURCE_BY_ID_SUCCESS';
export const DELETE_RESOURCE_BY_ID_FAILURE = 'DELETE_RESOURCE_BY_ID_FAILURE';

// END library

// START metric
export const GET_NUMERIC_METRICS_REQUEST = 'GET_NUMERIC_METRICS_REQUEST';
export const GET_NUMERIC_METRICS_SUCCESS = 'GET_NUMERIC_METRICS_SUCCESS';
export const GET_NUMERIC_METRICS_FAILURE = 'GET_NUMERIC_METRICS_FAILURE';

export const GET_NEW_STUDENTS_METRICS_REQUEST = 'GET_NEW_STUDENTS_METRICS_REQUEST';
export const GET_NEW_STUDENTS_METRICS_SUCCESS = 'GET_NEW_STUDENTS_METRICS_SUCCESS';
export const GET_NEW_STUDENTS_METRICS_FAILURE = 'GET_NEW_STUDENTS_METRICS_FAILURE';

export const GET_VISITORS_METRICS_REQUEST = 'GET_VISITORS_METRICS_REQUEST';
export const GET_VISITORS_METRICS_SUCCESS = 'GET_VISITORS_METRICS_SUCCESS';
export const GET_VISITORS_METRICS_FAILURE = 'GET_VISITORS_METRICS_FAILURE';

export const GET_STUDENTS_METRICS_BY_CITY_REQUEST = 'GET_STUDENTS_METRICS_BY_CITY_REQUEST';
export const GET_STUDENTS_METRICS_BY_CITY_SUCCESS = 'GET_STUDENTS_METRICS_BY_CITY_SUCCESS';
export const GET_STUDENTS_METRICS_BY_CITY_FAILURE = 'GET_STUDENTS_METRICS_BY_CITY_FAILURE';

export const GET_METRICS_BY_GENDER_REQUEST = 'GET_METRICS_BY_GENDER_REQUEST';
export const GET_METRICS_BY_GENDER_SUCCESS = 'GET_METRICS_BY_GENDER_SUCCESS';
export const GET_METRICS_BY_GENDER_FAILURE = 'GET_METRICS_BY_GENDER_FAILURE';

export const GET_METRICS_BY_SPECIAL_GROUPS_REQUEST = 'GET_METRICS_BY_SPECIAL_GROUPS_REQUEST';
export const GET_METRICS_BY_SPECIAL_GROUPS_SUCCESS = 'GET_METRICS_BY_SPECIAL_GROUPS_SUCCESS';
export const GET_METRICS_BY_SPECIAL_GROUPS_FAILURE = 'GET_METRICS_BY_SPECIAL_GROUPS_FAILURE';

export const GET_METRICS_BY_RESIDENCY_STATUS_REQUEST = 'GET_METRICS_BY_BY_RESIDENCY_STATUS_REQUEST';
export const GET_METRICS_BY_RESIDENCY_STATUS_SUCCESS = 'GET_METRICS_BY_RESIDENCY_STATUS_SUCCESS';
export const GET_METRICS_BY_RESIDENCY_STATUS_FAILURE = 'GET_METRICS_BY_RESIDENCY_STATUS_FAILURE';

export const GET_MOST_TAKEN_COURSES_METRICS_REQUEST = 'GET_MOST_TAKEN_COURSES_METRICS_REQUEST';
export const GET_MOST_TAKEN_COURSES_METRICS_SUCCESS = 'GET_MOST_TAKEN_COURSES_METRICS_SUCCESS';
export const GET_MOST_TAKEN_COURSES_METRICS_FAILURE = 'GET_MOST_TAKEN_COURSES_METRICS_FAILURE';

export const GET_MOST_DAILY_ACTIVE_USERS_METRICS_REQUEST = 'GET_MOST_DAILY_ACTIVE_USERS_METRICS_REQUEST';
export const GET_MOST_DAILY_ACTIVE_USERS_METRICS_SUCCESS = 'GET_MOST_DAILY_ACTIVE_USERS_METRICS_SUCCESS';
export const GET_MOST_DAILY_ACTIVE_USERS_METRICS_FAILURE = 'GET_MOST_DAILY_ACTIVE_USERS_METRICS_FAILURE';

export const SET_INNER_CHART_LINE_PAGE_DATA = 'SET_INNER_CHART_LINE_PAGE_DATA';

// END metric

// START profile
export const GET_PROFILE_INFO_REQUEST = 'GET_PROFILE_INFO_REQUEST';
export const GET_PROFILE_INFO_SUCCESS = 'GET_PROFILE_INFO_SUCCESS';
export const GET_PROFILE_INFO_FAILURE = 'GET_PROFILE_INFO_FAILURE';

export const PUT_PROFILE_INFO_REQUEST = 'PUT_PROFILE_INFO_REQUEST';
export const PUT_PROFILE_INFO_SUCCESS = 'PUT_PROFILE_INFO_SUCCESS';
export const PUT_PROFILE_INFO_FAILURE = 'PUT_PROFILE_INFO_FAILURE';

export const POST_PROFILE_PICTURE_REQUEST = 'POST_PROFILE_PICTURE_REQUEST';
export const POST_PROFILE_PICTURE_SUCCESS = 'POST_PROFILE_PICTURE_SUCCESS';
export const POST_PROFILE_PICTURE_FAILURE = 'POST_PROFILE_PICTURE_FAILURE';

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

export const GET_RESIDENCY_STATUS_LIST_REQUEST = 'GET_RESIDENCY_STATUS_LIST_REQUEST';
export const GET_RESIDENCY_STATUS_LIST_SUCCESS = 'GET_RESIDENCY_STATUS_LIST_SUCCESS';
export const GET_RESIDENCY_STATUS_LIST_FAILURE = 'GET_RESIDENCY_STATUS_LIST_FAILURE';

export const GET_GENDER_LIST_REQUEST = 'GET_GENDER_LIST_REQUEST';
export const GET_GENDER_LIST_SUCCESS = 'GET_GENDER_LIST_SUCCESS';
export const GET_GENDER_LIST_FAILURE = 'GET_GENDER_LIST_FAILURE';

export const GET_SPECIAL_GROUP_LIST_REQUEST = 'GET_SPECIAL_GROUP_LIST_REQUEST';
export const GET_SPECIAL_GROUP_LIST_SUCCESS = 'GET_SPECIAL_GROUP_LIST_SUCCESS';
export const GET_SPECIAL_GROUP_LIST_FAILURE = 'GET_SPECIAL_GROUP_LIST_FAILURE';

// END profile

// START content management
export const PUT_HOME_BANNER_REQUEST = 'PUT_HOME_BANNER_REQUEST';
export const PUT_HOME_BANNER_SUCCESS = 'PUT_HOME_BANNER_SUCCESS';
export const PUT_HOME_BANNER_FAILURE = 'PUT_HOME_BANNER_FAILURE';

export const PUT_HOME_SKILL_REQUEST = 'PUT_HOME_SKILL_REQUEST';
export const PUT_HOME_SKILL_SUCCESS = 'PUT_HOME_SKILL_SUCCESS';
export const PUT_HOME_SKILL_FAILURE = 'PUT_HOME_SKILL_FAILURE';

export const PUT_HOME_WHY_US_REQUEST = 'PUT_HOME_WHY_US_REQUEST';
export const PUT_HOME_WHY_US_SUCCESS = 'PUT_HOME_WHY_US_SUCCESS';
export const PUT_HOME_WHY_US_FAILURE = 'PUT_HOME_WHY_US_FAILURE';

export const PUT_HOME_POPULAR_REQUEST = 'PUT_HOME_POPULAR_REQUEST';
export const PUT_HOME_POPULAR_SUCCESS = 'PUT_HOME_POPULAR_SUCCESS';
export const PUT_HOME_POPULAR_FAILURE = 'PUT_HOME_POPULAR_FAILURE';

export const PUT_HOME_POPULAR_JOURNEY_REQUEST = 'PUT_HOME_POPULAR_JOURNEY_REQUEST';
export const PUT_HOME_POPULAR_JOURNEY_SUCCESS = 'PUT_HOME_POPULAR_JOURNEY_SUCCESS';
export const PUT_HOME_POPULAR_JOURNEY_FAILURE = 'PUT_HOME_POPULAR_JOURNEY_FAILURE';

export const PUT_HOME_ENDORSEMENT_REQUEST = 'PUT_HOME_ENDORSEMENT_REQUEST';
export const PUT_HOME_ENDORSEMENT_SUCCESS = 'PUT_HOME_ENDORSEMENT_SUCCESS';
export const PUT_HOME_ENDORSEMENT_FAILURE = 'PUT_HOME_ENDORSEMENT_FAILURE';

export const PUT_HOME_SUPPORTED_BY_REQUEST = 'PUT_HOME_SUPPORTED_BY_REQUEST';
export const PUT_HOME_SUPPORTED_BY_SUCCESS = 'PUT_HOME_SUPPORTED_BY_SUCCESS';
export const PUT_HOME_SUPPORTED_BY_FAILURE = 'PUT_HOME_SUPPORTED_BY_FAILURE';

export const PUT_HOME_JOIN_US_REQUEST = 'PUT_HOME_JOIN_US_REQUEST';
export const PUT_HOME_JOIN_US_SUCCESS = 'PUT_HOME_JOIN_US_SUCCESS';
export const PUT_HOME_JOIN_US_FAILURE = 'PUT_HOME_JOIN_US_FAILURE';

export const PUT_FOOTER_REQUEST = 'PUT_FOOTER_REQUEST';
export const PUT_FOOTER_SUCCESS = 'PUT_FOOTER_SUCCESS';
export const PUT_FOOTER_FAILURE = 'PUT_FOOTER_FAILURE';

export const PUT_CONTACT_US_REQUEST = 'PUT_CONTACT_US_REQUEST';
export const PUT_CONTACT_US_SUCCESS = 'PUT_CONTACT_US_SUCCESS';
export const PUT_CONTACT_US_FAILURE = 'PUT_CONTACT_US_FAILURE';

export const PUT_FOR_NEW_CAREER_BANNER_REQUEST = 'PUT_FOR_NEW_CAREER_BANNER_REQUEST';
export const PUT_FOR_NEW_CAREER_BANNER_SUCCESS = 'PUT_FOR_NEW_CAREER_BANNER_SUCCESS';
export const PUT_FOR_NEW_CAREER_BANNER_FAILURE = 'PUT_FOR_NEW_CAREER_BANNER_FAILURE';

export const PUT_FOR_NEW_CAREER_SUPPORTED_BY_REQUEST = 'PUT_FOR_NEW_CAREER_SUPPORTED_BY_REQUEST';
export const PUT_FOR_NEW_CAREER_SUPPORTED_BY_SUCCESS = 'PUT_FOR_NEW_CAREER_SUPPORTED_BY_SUCCESS';
export const PUT_FOR_NEW_CAREER_SUPPORTED_BY_FAILURE = 'PUT_FOR_NEW_CAREER_SUPPORTED_BY_FAILURE';

export const PUT_FOR_NEW_CAREER_COURSE_FILTER_REQUEST = 'PUT_FOR_NEW_CAREER_COURSE_FILTER_REQUEST';
export const PUT_FOR_NEW_CAREER_COURSE_FILTER_SUCCESS = 'PUT_FOR_NEW_CAREER_COURSE_FILTER_SUCCESS';
export const PUT_FOR_NEW_CAREER_COURSE_FILTER_FAILURE = 'PUT_FOR_NEW_CAREER_COURSE_FILTER_FAILURE';

export const PUT_FOR_ENTERPRISE_BANNER_REQUEST = 'PUT_FOR_ENTERPRISE_BANNER_REQUEST';
export const PUT_FOR_ENTERPRISE_BANNER_SUCCESS = 'PUT_FOR_ENTERPRISE_BANNER_SUCCESS';
export const PUT_FOR_ENTERPRISE_BANNER_FAILURE = 'PUT_FOR_ENTERPRISE_BANNER_FAILURE';

export const PUT_FOR_ENTERPRISE_SUPPORTED_BY_REQUEST = 'PUT_FOR_ENTERPRISE_SUPPORTED_BY_REQUEST';
export const PUT_FOR_ENTERPRISE_SUPPORTED_BY_SUCCESS = 'PUT_FOR_ENTERPRISE_SUPPORTED_BY_SUCCESS';
export const PUT_FOR_ENTERPRISE_SUPPORTED_BY_FAILURE = 'PUT_FOR_ENTERPRISE_SUPPORTED_BY_FAILURE';

export const PUT_FOR_ENTERPRISE_COURSE_FILTER_REQUEST = 'PUT_FOR_ENTERPRISE_COURSE_FILTER_REQUEST';
export const PUT_FOR_ENTERPRISE_COURSE_FILTER_SUCCESS = 'PUT_FOR_ENTERPRISE_COURSE_FILTER_SUCCESS';
export const PUT_FOR_ENTERPRISE_COURSE_FILTER_FAILURE = 'PUT_FOR_ENTERPRISE_COURSE_FILTER_FAILURE';

export const PUT_FOR_UNIVERSITIES_BANNER_REQUEST = 'PUT_FOR_UNIVERSITIES_BANNER_REQUEST';
export const PUT_FOR_UNIVERSITIES_BANNER_SUCCESS = 'PUT_FOR_UNIVERSITIES_BANNER_SUCCESS';
export const PUT_FOR_UNIVERSITIES_BANNER_FAILURE = 'PUT_FOR_UNIVERSITIES_BANNER_FAILURE';

export const PUT_FOR_UNIVERSITIES_SUPPORTED_BY_REQUEST = 'PUT_FOR_UNIVERSITIES_SUPPORTED_BY_REQUEST';
export const PUT_FOR_UNIVERSITIES_SUPPORTED_BY_SUCCESS = 'PUT_FOR_UNIVERSITIES_SUPPORTED_BY_SUCCESS';
export const PUT_FOR_UNIVERSITIES_SUPPORTED_BY_FAILURE = 'PUT_FOR_UNIVERSITIES_SUPPORTED_BY_FAILURE';

export const PUT_FOR_UNIVERSITIES_COURSE_FILTER_REQUEST = 'PUT_FOR_UNIVERSITIES_COURSE_FILTER_REQUEST';
export const PUT_FOR_UNIVERSITIES_COURSE_FILTER_SUCCESS = 'PUT_FOR_UNIVERSITIES_COURSE_FILTER_SUCCESS';
export const PUT_FOR_UNIVERSITIES_COURSE_FILTER_FAILURE = 'PUT_FOR_UNIVERSITIES_COURSE_FILTER_FAILURE';

export const GET_HOME_BANNER_REQUEST = 'GET_HOME_BANNER_REQUEST';
export const GET_HOME_BANNER_SUCCESS = 'GET_HOME_BANNER_SUCCESS';
export const GET_HOME_BANNER_FAILURE = 'GET_HOME_BANNER_FAILURE';

export const GET_HOME_SKILL_REQUEST = 'GET_HOME_SKILL_REQUEST';
export const GET_HOME_SKILL_SUCCESS = 'GET_HOME_SKILL_SUCCESS';
export const GET_HOME_SKILL_FAILURE = 'GET_HOME_SKILL_FAILURE';

export const GET_HOME_WHY_US_REQUEST = 'GET_HOME_WHY_US_REQUEST';
export const GET_HOME_WHY_US_SUCCESS = 'GET_HOME_WHY_US_SUCCESS';
export const GET_HOME_WHY_US_FAILURE = 'GET_HOME_WHY_US_FAILURE';

export const GET_HOME_POPULAR_REQUEST = 'GET_HOME_POPULAR_REQUEST';
export const GET_HOME_POPULAR_SUCCESS = 'GET_HOME_POPULAR_SUCCESS';
export const GET_HOME_POPULAR_FAILURE = 'GET_HOME_POPULAR_FAILURE';

export const GET_HOME_POPULAR_JOURNEY_REQUEST = 'GET_HOME_POPULAR_JOURNEY_REQUEST';
export const GET_HOME_POPULAR_JOURNEY_SUCCESS = 'GET_HOME_POPULAR_JOURNEY_SUCCESS';
export const GET_HOME_POPULAR_JOURNEY_FAILURE = 'GET_HOME_POPULAR_JOURNEY_FAILURE';

export const GET_HOME_ENDORSEMENT_REQUEST = 'GET_HOME_ENDORSEMENT_REQUEST';
export const GET_HOME_ENDORSEMENT_SUCCESS = 'GET_HOME_ENDORSEMENT_SUCCESS';
export const GET_HOME_ENDORSEMENT_FAILURE = 'GET_HOME_ENDORSEMENT_FAILURE';

export const GET_HOME_SUPPORTED_BY_REQUEST = 'GET_HOME_SUPPORTED_BY_REQUEST';
export const GET_HOME_SUPPORTED_BY_SUCCESS = 'GET_HOME_SUPPORTED_BY_SUCCESS';
export const GET_HOME_SUPPORTED_BY_FAILURE = 'GET_HOME_SUPPORTED_BY_FAILURE';

export const GET_HOME_JOIN_US_REQUEST = 'GET_HOME_JOIN_US_REQUEST';
export const GET_HOME_JOIN_US_SUCCESS = 'GET_HOME_JOIN_US_SUCCESS';
export const GET_HOME_JOIN_US_FAILURE = 'GET_HOME_JOIN_US_FAILURE';

export const GET_FOOTER_REQUEST = 'GET_FOOTER_REQUEST';
export const GET_FOOTER_SUCCESS = 'GET_FOOTER_SUCCESS';
export const GET_FOOTER_FAILURE = 'GET_FOOTER_FAILURE';

export const GET_CONTACT_US_REQUEST = 'GET_CONTACT_US_REQUEST';
export const GET_CONTACT_US_SUCCESS = 'GET_CONTACT_US_SUCCESS';
export const GET_CONTACT_US_FAILURE = 'GET_CONTACT_US_FAILURE';

export const GET_FOR_NEW_CAREER_BANNER_REQUEST = 'GET_FOR_NEW_CAREER_BANNER_REQUEST';
export const GET_FOR_NEW_CAREER_BANNER_SUCCESS = 'GET_FOR_NEW_CAREER_BANNER_SUCCESS';
export const GET_FOR_NEW_CAREER_BANNER_FAILURE = 'GET_FOR_NEW_CAREER_BANNER_FAILURE';

export const GET_FOR_NEW_CAREER_SUPPORTED_BY_REQUEST = 'GET_FOR_NEW_CAREER_SUPPORTED_BY_REQUEST';
export const GET_FOR_NEW_CAREER_SUPPORTED_BY_SUCCESS = 'GET_FOR_NEW_CAREER_SUPPORTED_BY_SUCCESS';
export const GET_FOR_NEW_CAREER_SUPPORTED_BY_FAILURE = 'GET_FOR_NEW_CAREER_SUPPORTED_BY_FAILURE';

export const GET_FOR_NEW_CAREER_COURSE_FILTER_REQUEST = 'GET_FOR_NEW_CAREER_COURSE_FILTER_REQUEST';
export const GET_FOR_NEW_CAREER_COURSE_FILTER_SUCCESS = 'GET_FOR_NEW_CAREER_COURSE_FILTER_SUCCESS';
export const GET_FOR_NEW_CAREER_COURSE_FILTER_FAILURE = 'GET_FOR_NEW_CAREER_COURSE_FILTER_FAILURE';

export const GET_FOR_ENTERPRISE_BANNER_REQUEST = 'GET_FOR_ENTERPRISE_BANNER_REQUEST';
export const GET_FOR_ENTERPRISE_BANNER_SUCCESS = 'GET_FOR_ENTERPRISE_BANNER_SUCCESS';
export const GET_FOR_ENTERPRISE_BANNER_FAILURE = 'GET_FOR_ENTERPRISE_BANNER_FAILURE';

export const GET_FOR_ENTERPRISE_SUPPORTED_BY_REQUEST = 'GET_FOR_ENTERPRISE_SUPPORTED_BY_REQUEST';
export const GET_FOR_ENTERPRISE_SUPPORTED_BY_SUCCESS = 'GET_FOR_ENTERPRISE_SUPPORTED_BY_SUCCESS';
export const GET_FOR_ENTERPRISE_SUPPORTED_BY_FAILURE = 'GET_FOR_ENTERPRISE_SUPPORTED_BY_FAILURE';

export const GET_FOR_ENTERPRISE_COURSE_FILTER_REQUEST = 'GET_FOR_ENTERPRISE_COURSE_FILTER_REQUEST';
export const GET_FOR_ENTERPRISE_COURSE_FILTER_SUCCESS = 'GET_FOR_ENTERPRISE_COURSE_FILTER_SUCCESS';
export const GET_FOR_ENTERPRISE_COURSE_FILTER_FAILURE = 'GET_FOR_ENTERPRISE_COURSE_FILTER_FAILURE';

export const GET_FOR_UNIVERSITIES_BANNER_REQUEST = 'GET_FOR_UNIVERSITIES_BANNER_REQUEST';
export const GET_FOR_UNIVERSITIES_BANNER_SUCCESS = 'GET_FOR_UNIVERSITIES_BANNER_SUCCESS';
export const GET_FOR_UNIVERSITIES_BANNER_FAILURE = 'GET_FOR_UNIVERSITIES_BANNER_FAILURE';

export const GET_FOR_UNIVERSITIES_SUPPORTED_BY_REQUEST = 'GET_FOR_UNIVERSITIES_SUPPORTED_BY_REQUEST';
export const GET_FOR_UNIVERSITIES_SUPPORTED_BY_SUCCESS = 'GET_FOR_UNIVERSITIES_SUPPORTED_BY_SUCCESS';
export const GET_FOR_UNIVERSITIES_SUPPORTED_BY_FAILURE = 'GET_FOR_UNIVERSITIES_SUPPORTED_BY_FAILURE';

export const GET_FOR_UNIVERSITIES_COURSE_FILTER_REQUEST = 'GET_FOR_UNIVERSITIES_COURSE_FILTER_REQUEST';
export const GET_FOR_UNIVERSITIES_COURSE_FILTER_SUCCESS = 'GET_FOR_UNIVERSITIES_COURSE_FILTER_SUCCESS';
export const GET_FOR_UNIVERSITIES_COURSE_FILTER_FAILURE = 'GET_FOR_UNIVERSITIES_COURSE_FILTER_FAILURE';

export const PUT_ORG_REQUEST = 'PUT_ORG_REQUEST';
export const PUT_ORG_SUCCESS = 'PUT_ORG_SUCCESS';
export const PUT_ORG_FAILURE = 'PUT_ORG_FAILURE';

export const GET_ORG_REQUEST = 'GET_ORG_REQUEST';
export const GET_ORG_SUCCESS = 'GET_ORG_SUCCESS';
export const GET_ORG_FAILURE = 'GET_ORG_FAILURE';

export const PUT_ABOUT_REQUEST = 'PUT_ABOUT_REQUEST';
export const PUT_ABOUT_SUCCESS = 'PUT_ABOUT_SUCCESS';
export const PUT_ABOUT_FAILURE = 'PUT_ABOUT_FAILURE';

export const GET_ABOUT_REQUEST = 'GET_ABOUT_REQUEST';
export const GET_ABOUT_SUCCESS = 'GET_ABOUT_SUCCESS';
export const GET_ABOUT_FAILURE = 'GET_ABOUT_FAILURE';

export const PUT_HEADER_REQUEST = 'PUT_HEADER_REQUEST';
export const PUT_HEADER_SUCCESS = 'PUT_HEADER_SUCCESS';
export const PUT_HEADER_FAILURE = 'PUT_HEADER_FAILURE';

export const GET_HEADER_REQUEST = 'GET_HEADER_REQUEST';
export const GET_HEADER_SUCCESS = 'GET_HEADER_SUCCESS';
export const GET_HEADER_FAILURE = 'GET_HEADER_FAILURE';

// END content management

// START message
export const GET_CONTACT_US_MESSAGE_REQUEST = 'GET_CONTACT_US_MESSAGE_REQUEST';
export const GET_CONTACT_US_MESSAGE_SUCCESS = 'GET_CONTACT_US_MESSAGE_SUCCESS';
export const GET_CONTACT_US_MESSAGE_FAILURE = 'GET_CONTACT_US_MESSAGE_FAILURE';

export const GET_CONTACT_MESSAGE_BY_ID_REQUEST = 'GET_CONTACT_MESSAGE_BY_ID_REQUEST';
export const GET_CONTACT_MESSAGE_BY_ID_SUCCESS = 'GET_CONTACT_MESSAGE_BY_ID_SUCCESS';
export const GET_CONTACT_MESSAGE_BY_ID_FAILURE = 'GET_CONTACT_MESSAGE_BY_ID_FAILURE';

export const GET_NEWS_LETTER_SUBSCRIPTION_REQUEST = 'GET_NEWS_LETTER_SUBSCRIPTION_REQUEST';
export const GET_NEWS_LETTER_SUBSCRIPTION_SUCCESS = 'GET_NEWS_LETTER_SUBSCRIPTION_SUCCESS';
export const GET_NEWS_LETTER_SUBSCRIPTION_FAILURE = 'GET_NEWS_LETTER_SUBSCRIPTION_FAILURE';

// END message

// START notification
export const GET_ALL_NOTIFICATION_SETTINGS_REQUEST = 'GET_ALL_NOTIFICATION_SETTINGS_REQUEST';
export const GET_ALL_NOTIFICATION_SETTINGS_SUCCESS = 'GET_ALL_NOTIFICATION_SETTINGS_SUCCESS';
export const GET_ALL_NOTIFICATION_SETTINGS_FAILURE = 'GET_ALL_NOTIFICATION_SETTINGS_FAILURE';

export const PUT_ALL_NOTIFICATION_SETTINGS_REQUEST = 'PUT_ALL_NOTIFICATION_SETTINGS_REQUEST';
export const PUT_ALL_NOTIFICATION_SETTINGS_SUCCESS = 'PUT_ALL_NOTIFICATION_SETTINGS_SUCCESS';
export const PUT_ALL_NOTIFICATION_SETTINGS_FAILURE = 'PUT_ALL_NOTIFICATION_SETTINGS_FAILURE';

export const GET_SENT_NOTIFICATIONS_REQUEST = 'GET_SENT_NOTIFICATIONS_REQUEST';
export const GET_SENT_NOTIFICATIONS_SUCCESS = 'GET_SENT_NOTIFICATIONS_SUCCESS';
export const GET_SENT_NOTIFICATIONS_FAILURE = 'GET_SENT_NOTIFICATIONS_FAILURE';

export const SEND_SMS_NOTIFICATION_SINGLE_REQUEST = 'SEND_SMS_NOTIFICATION_SINGLE_REQUEST';
export const SEND_SMS_NOTIFICATION_SINGLE_SUCCESS = 'SEND_SMS_NOTIFICATION_SINGLE_SUCCESS';
export const SEND_SMS_NOTIFICATION_SINGLE_FAILURE = 'SEND_SMS_NOTIFICATION_SINGLE_FAILURE';

export const SEND_SMS_NOTIFICATION_TO_FILTERED_USERS_REQUEST = 'SEND_SMS_NOTIFICATION_TO_FILTERED_USERS_REQUEST';
export const SEND_SMS_NOTIFICATION_TO_FILTERED_USERS_SUCCESS = 'SEND_SMS_NOTIFICATION_TO_FILTERED_USERS_SUCCESS';
export const SEND_SMS_NOTIFICATION_TO_FILTERED_USERS_FAILURE = 'SEND_SMS_NOTIFICATION_TO_FILTERED_USERS_FAILURE';

export const SEND_SMS_NOTIFICATION_TO_COURSE_USERS_REQUEST = 'SEND_SMS_NOTIFICATION_TO_COURSE_USERS_REQUEST';
export const SEND_SMS_NOTIFICATION_TO_COURSE_USERS_SUCCESS = 'SEND_SMS_NOTIFICATION_TO_COURSE_USERS_SUCCESS';
export const SEND_SMS_NOTIFICATION_TO_COURSE_USERS_FAILURE = 'SEND_SMS_NOTIFICATION_TO_COURSE_USERS_FAILURE';

export const SET_SEND_NOTIFICATION_PAGE_DATA = 'SET_SEND_NOTIFICATION_PAGE_DATA';

// END notification
