import React, { Suspense } from 'react';
import { Navigate, Route, Routes, unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import Spinner from './components/spinner';
import * as storage from './helpers/localStorage';
import history from './helpers/history';
import { connect } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import routes from './routing/routes';
import './scss/style.scss';

const App = props => {
	const {} = props;

	const validPath = ['/login'];

	if (!storage.getLocalStorage('tokenInfo') && validPath.indexOf(window.location.pathname) === -1) {
		window.location.replace('/login');
		return null;
	} else if (storage.getLocalStorage('tokenInfo') && validPath.indexOf(window.location.pathname) !== -1) {
		window.location.replace('/');
		return null;
	} else {
		return (
			<HistoryRouter history={history}>
				<ToastContainer
					position="bottom-right"
					autoClose={parseInt(process.env.REACT_APP_TOASTER_CLOSE_TIMEOUT, 10)}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
				<Suspense fallback={Spinner}>
					<Routes>
						{routes.map((route, idx) => {
							return (
								route.element && (
									<Route
										key={idx}
										path={route.path}
										exact={route.exact}
										name={route.name}
										element={
											<route.layout>
												<route.element />
											</route.layout>
										}
									/>
								)
							);
						})}
						<Route path="/" element={<Navigate to="dashboard" replace />} />
					</Routes>
				</Suspense>
			</HistoryRouter>
		);
	}
};

const mapStateToProps = state => {
	return {};
};

const mapDispatchToProps = dispatch => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
