import { combineReducers } from 'redux';
import global from './global';
import auth from './auth';
import user from './user';
import game from './game';
import general from './general';
import subject from './subject';
import course from './course';
import journey from './journey';
import comment from './comment';
import metric from './metric';
import library from './library';
import profile from './profile';
import contentManagement from './contentManagement';
import message from './message';
import notification from './notification';

export default () =>
	combineReducers({
		global,
		auth,
		user,
		game,
		general,
		subject,
		course,
		journey,
		comment,
		library,
		metric,
		profile,
		contentManagement,
		message,
		notification,
	});
