import * as actionTypes from '../constants/actionTypes';

const initialState = {
	allComments: {
		loading: false,
		data: null,
	},
	commentsFilter: {
		page: 0,
		size: 6,
	},
	commentById: {
		loading: false,
		data: null,
	},
	replyCommentByAdmin: {
		loading: false,
		data: null,
	},
	deleteCommentByAdmin: {
		loading: false,
		data: null,
	},
};

const Comment = (state = initialState, { type, payload }) => {
	switch (type) {
		case actionTypes.GET_ALL_COMMENTS_REQUEST:
			return {
				...state,
				allComments: { ...state.allComments, loading: true },
			};
		case actionTypes.GET_ALL_COMMENTS_SUCCESS:
			return {
				...state,
				allComments: {
					loading: false,
					data: payload,
				},
			};
		case actionTypes.GET_ALL_COMMENTS_FAILURE:
			return {
				...state,
				allComments: { loading: false, data: null },
			};

		case actionTypes.SET_COMMENTS_FILTER:
			return {
				...state,
				commentsFilter: {
					...state.commentsFilter,
					...payload,
				},
			};

		case actionTypes.GET_COMMENT_BY_ID_REQUEST:
			return {
				...state,
				commentById: { ...state.commentById, loading: true },
			};
		case actionTypes.GET_COMMENT_BY_ID_SUCCESS:
			return {
				...state,
				commentById: { loading: false, data: payload },
			};
		case actionTypes.GET_COMMENT_BY_ID_FAILURE:
			return {
				...state,
				commentById: { loading: false, data: null },
			};

		case actionTypes.REPLY_COMMENT_BY_ADMIN_REQUEST:
			return {
				...state,
				replyCommentByAdmin: { ...state.replyCommentByAdmin, loading: true },
			};
		case actionTypes.REPLY_COMMENT_BY_ADMIN_SUCCESS:
			return {
				...state,
				replyCommentByAdmin: { loading: false, data: payload },
			};
		case actionTypes.REPLY_COMMENT_BY_ADMIN_FAILURE:
			return {
				...state,
				replyCommentByAdmin: { loading: false, data: null },
			};

		case actionTypes.DELETE_COMMENT_BY_ADMIN_REQUEST:
			return {
				...state,
				deleteCommentByAdmin: { ...state.deleteCommentByAdmin, loading: true },
			};
		case actionTypes.DELETE_COMMENT_BY_ADMIN_SUCCESS:
			return {
				...state,
				deleteCommentByAdmin: { loading: false, data: payload },
			};
		case actionTypes.DELETE_COMMENT_BY_ADMIN_FAILURE:
			return {
				...state,
				deleteCommentByAdmin: { loading: false, data: null },
			};

		default:
			return state;
	}
};

export default Comment;
