import * as actionTypes from '../constants/actionTypes';

export const initialState = {
	allResources: {
		loading: false,
		data: null,
	},
	adminResources: {
		loading: false,
		data: null,
	},
	postResource: {
		loading: false,
		data: null,
	},
	resourceById: {
		loading: false,
		data: null,
	},
	deleteResourceById: {
		loading: false,
		data: null,
	},

	putResourceById: {
		loading: false,
		data: null,
	},
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case actionTypes.GET_ALL_RESOURCES_LOADING:
			return {
				...state,
				allResources: { ...state.allResources, loading: true },
			};
		case actionTypes.GET_ALL_RESOURCES_SUCCESS:
			return {
				...state,
				allResources: { loading: false, data: payload },
			};
		case actionTypes.GET_ALL_RESOURCES_FAILURE:
			return {
				...state,
				allResources: { loading: false, data: null },
			};

		case actionTypes.GET_ADMIN_RESOURCES_LOADING:
			return {
				...state,
				adminResources: { ...state.adminResources, loading: true },
			};
		case actionTypes.GET_ADMIN_RESOURCES_SUCCESS:
			return {
				...state,
				adminResources: { loading: false, data: payload },
			};
		case actionTypes.GET_ADMIN_RESOURCES_FAILURE:
			return {
				...state,
				adminResources: { loading: false, data: null },
			};

		case actionTypes.POST_RESOURCE_LOADING:
			return {
				...state,
				postResource: { ...state.postResource, loading: true },
			};
		case actionTypes.POST_RESOURCE_SUCCESS:
			return {
				...state,
				postResource: { loading: false, data: payload },
			};
		case actionTypes.POST_RESOURCE_FAILURE:
			return {
				...state,
				postResource: { loading: false, data: null },
			};

		case actionTypes.GET_RESOURCE_LOADING:
			return {
				...state,
				resourceById: { ...state.resourceById, loading: true },
			};
		case actionTypes.GET_RESOURCE_SUCCESS:
			return {
				...state,
				resourceById: { loading: false, data: payload },
			};
		case actionTypes.GET_RESOURCE_FAILURE:
			return {
				...state,
				resourceById: { loading: false, data: null },
			};

		case actionTypes.DELETE_RESOURCE_BY_ID_LOADING:
			return {
				...state,
				deleteResourceById: { ...state.deleteResourceById, loading: true },
			};
		case actionTypes.DELETE_RESOURCE_BY_ID_SUCCESS:
			return {
				...state,
				deleteResourceById: { loading: false, data: payload },
			};
		case actionTypes.DELETE_RESOURCE_BY_ID_FAILURE:
			return {
				...state,
				deleteResourceById: { loading: false, data: null },
			};

		case actionTypes.PUT_RESOURCE_BY_ID_LOADING:
			return {
				...state,
				putResourceById: { ...state.putResourceById, loading: true },
			};
		case actionTypes.PUT_RESOURCE_BY_ID_SUCCESS:
			return {
				...state,
				putResourceById: { loading: false, data: payload },
			};
		case actionTypes.PUT_RESOURCE_BY_ID_FAILURE:
			return {
				...state,
				putResourceById: { loading: false, data: null },
			};

		default:
			return state;
	}
};
