import * as actionTypes from '../constants/actionTypes';

const initialState = {
	contactUsMessage: {
		loading: false,
		data: null,
	},
	contactMessageById: {
		loading: false,
		data: null,
	},
	newsLetterSubscription: {
		loading: false,
		data: null,
	},
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case actionTypes.GET_CONTACT_US_MESSAGE_REQUEST:
			return {
				...state,
				contactUsMessage: { ...state.contactUsMessage, loading: true },
			};
		case actionTypes.GET_CONTACT_US_MESSAGE_SUCCESS:
			return {
				...state,
				contactUsMessage: { loading: false, data: payload },
			};
		case actionTypes.GET_CONTACT_US_MESSAGE_FAILURE:
			return {
				...state,
				contactUsMessage: { loading: false, data: null },
			};

		case actionTypes.GET_CONTACT_MESSAGE_BY_ID_REQUEST:
			return {
				...state,
				contactMessageById: { ...state.contactMessageById, loading: true },
			};
		case actionTypes.GET_CONTACT_MESSAGE_BY_ID_SUCCESS:
			return {
				...state,
				contactMessageById: { loading: false, data: payload },
			};
		case actionTypes.GET_CONTACT_MESSAGE_BY_ID_FAILURE:
			return {
				...state,
				contactMessageById: { loading: false, data: null },
			};

		case actionTypes.GET_NEWS_LETTER_SUBSCRIPTION_REQUEST:
			return {
				...state,
				newsLetterSubscription: { ...state.newsLetterSubscription, loading: true },
			};
		case actionTypes.GET_NEWS_LETTER_SUBSCRIPTION_SUCCESS:
			return {
				...state,
				newsLetterSubscription: { loading: false, data: payload },
			};
		case actionTypes.GET_NEWS_LETTER_SUBSCRIPTION_FAILURE:
			return {
				...state,
				newsLetterSubscription: { loading: false, data: null },
			};

		default:
			return state;
	}
};
