import * as actionTypes from '../constants/actionTypes';

const initialState = {
	login: {
		loading: false,
		data: null,
	},
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case actionTypes.LOGIN_REQUEST:
			return {
				...state,
				login: { loading: true, data: null },
			};
		case actionTypes.LOGIN_SUCCESS:
			return {
				...state,
				login: { loading: false, data: payload },
			};
		case actionTypes.LOGIN_FAILURE:
			return {
				...state,
				login: { loading: false, data: null },
			};

		default:
			return state;
	}
};
