import * as actionTypes from '../constants/actionTypes';
import { element } from 'prop-types';

const initialState = {
	putHomeBanner: {
		loading: false,
		data: null,
	},
	putHomeSkill: {
		loading: false,
		data: null,
	},
	putHomeWhyUs: {
		loading: false,
		data: null,
	},
	putHomePopular: {
		loading: false,
		data: null,
	},
	putHomePopularJourney: {
		loading: false,
		data: null,
	},
	putHomeEndorsement: {
		loading: false,
		data: null,
	},
	putHomeSupportedBy: {
		loading: false,
		data: null,
	},
	putHomeJoinUs: {
		loading: false,
		data: null,
	},
	putFooter: {
		loading: false,
		data: null,
	},
	putContactUs: {
		loading: false,
		data: null,
	},
	putForNewCareerBanner: {
		loading: false,
		data: null,
	},
	putForNewCareerSupportedBy: {
		loading: false,
		data: null,
	},
	putForNewCareerCourseFilter: {
		loading: false,
		data: null,
	},
	putForEnterpriseBanner: {
		loading: false,
		data: null,
	},
	putForEnterpriseSupportedBy: {
		loading: false,
		data: null,
	},
	putForEnterpriseCourseFilter: {
		loading: false,
		data: null,
	},
	putForUniversitiesBanner: {
		loading: false,
		data: null,
	},
	putForUniversitiesSupportedBy: {
		loading: false,
		data: null,
	},
	putForUniversitiesCourseFilter: {
		loading: false,
		data: null,
	},
	getHomeBanner: {
		loading: false,
		data: null,
	},
	getHomeSkill: {
		loading: false,
		data: null,
	},
	getHomeWhyUs: {
		loading: false,
		data: null,
	},
	getHomePopular: {
		loading: false,
		data: null,
	},
	getHomePopularJourney: {
		loading: false,
		data: null,
	},
	getHomeEndorsement: {
		loading: false,
		data: null,
	},
	getHomeSupportedBy: {
		loading: false,
		data: null,
	},
	getHomeJoinUs: {
		loading: false,
		data: null,
	},
	getFooter: {
		loading: false,
		data: null,
	},
	getContactUs: {
		loading: false,
		data: null,
	},
	getForNewCareerBanner: {
		loading: false,
		data: null,
	},
	getForNewCareerSupportedBy: {
		loading: false,
		data: null,
	},
	getForNewCareerCourseFilter: {
		loading: false,
		data: null,
	},
	getForEnterpriseBanner: {
		loading: false,
		data: null,
	},
	getForEnterpriseSupportedBy: {
		loading: false,
		data: null,
	},
	getForEnterpriseCourseFilter: {
		loading: false,
		data: null,
	},
	getForUniversitiesBanner: {
		loading: false,
		data: null,
	},
	getForUniversitiesSupportedBy: {
		loading: false,
		data: null,
	},
	getForUniversitiesCourseFilter: {
		loading: false,
		data: null,
	},
	putOrg: {
		loading: false,
		data: null,
	},
	getOrg: {
		loading: false,
		data: null,
	},
	putAbout: {
		loading: false,
		data: null,
	},
	getAbout: {
		loading: false,
		data: null,
	},
	putHeader: {
		loading: false,
		data: null,
	},
	getHeader: {
		loading: false,
		data: null,
	},
};

const payloadByElementIdData = payload => {
	const elementIds = [];
	const data = {};

	if (payload?.length > 0) {
		for (let el of payload) {
			elementIds.push(el.elementId);
		}

		for (let el of new Set([...elementIds])) {
			for (let j of payload) {
				if (j.elementId === el) {
					const list = data[el];

					if (list) {
						data[el] = [...data[el], j];
					} else {
						data[el] = [j];
					}
				}
			}
		}
	}

	return data;
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case actionTypes.PUT_HOME_BANNER_REQUEST:
			return {
				...state,
				putHomeBanner: { ...state.putHomeBanner, loading: true },
			};
		case actionTypes.PUT_HOME_BANNER_SUCCESS:
			return {
				...state,
				putHomeBanner: { loading: false, data: payload },
			};
		case actionTypes.PUT_HOME_BANNER_FAILURE:
			return {
				...state,
				putHomeBanner: { loading: false, data: null },
			};

		case actionTypes.PUT_HOME_SKILL_REQUEST:
			return {
				...state,
				putHomeSkill: { ...state.putHomeSkill, loading: true },
			};
		case actionTypes.PUT_HOME_SKILL_SUCCESS:
			return {
				...state,
				putHomeSkill: { loading: false, data: payload },
			};
		case actionTypes.PUT_HOME_SKILL_FAILURE:
			return {
				...state,
				putHomeSkill: { loading: false, data: null },
			};

		case actionTypes.PUT_HOME_WHY_US_REQUEST:
			return {
				...state,
				putHomeWhyUs: { ...state.putHomeWhyUs, loading: true },
			};
		case actionTypes.PUT_HOME_WHY_US_SUCCESS:
			return {
				...state,
				putHomeWhyUs: { loading: false, data: payload },
			};
		case actionTypes.PUT_HOME_WHY_US_FAILURE:
			return {
				...state,
				putHomeWhyUs: { loading: false, data: null },
			};

		case actionTypes.PUT_HOME_POPULAR_REQUEST:
			return {
				...state,
				putHomePopular: { ...state.putHomePopular, loading: true },
			};
		case actionTypes.PUT_HOME_POPULAR_SUCCESS:
			return {
				...state,
				putHomePopular: { loading: false, data: payload },
			};
		case actionTypes.PUT_HOME_POPULAR_FAILURE:
			return {
				...state,
				putHomePopular: { loading: false, data: null },
			};

		case actionTypes.PUT_HOME_POPULAR_JOURNEY_REQUEST:
			return {
				...state,
				putHomePopularJourney: { ...state.putHomePopularJourney, loading: true },
			};
		case actionTypes.PUT_HOME_POPULAR_JOURNEY_SUCCESS:
			return {
				...state,
				putHomePopularJourney: { loading: false, data: payload },
			};
		case actionTypes.PUT_HOME_POPULAR_JOURNEY_FAILURE:
			return {
				...state,
				putHomePopularJourney: { loading: false, data: null },
			};

		case actionTypes.PUT_HOME_ENDORSEMENT_REQUEST:
			return {
				...state,
				putHomeEndorsement: { ...state.putHomeEndorsement, loading: true },
			};
		case actionTypes.PUT_HOME_ENDORSEMENT_SUCCESS:
			return {
				...state,
				putHomeEndorsement: { loading: false, data: payload },
			};
		case actionTypes.PUT_HOME_ENDORSEMENT_FAILURE:
			return {
				...state,
				putHomeEndorsement: { loading: false, data: null },
			};

		case actionTypes.PUT_HOME_SUPPORTED_BY_REQUEST:
			return {
				...state,
				putHomeSupportedBy: { ...state.putHomeSupportedBy, loading: true },
			};
		case actionTypes.PUT_HOME_SUPPORTED_BY_SUCCESS:
			return {
				...state,
				putHomeSupportedBy: { loading: false, data: payload },
			};
		case actionTypes.PUT_HOME_SUPPORTED_BY_FAILURE:
			return {
				...state,
				putHomeSupportedBy: { loading: false, data: null },
			};

		case actionTypes.PUT_HOME_JOIN_US_REQUEST:
			return {
				...state,
				putHomeJoinUs: { ...state.putHomeJoinUs, loading: true },
			};
		case actionTypes.PUT_HOME_JOIN_US_SUCCESS:
			return {
				...state,
				putHomeJoinUs: { loading: false, data: payload },
			};
		case actionTypes.PUT_HOME_JOIN_US_FAILURE:
			return {
				...state,
				putHomeJoinUs: { loading: false, data: null },
			};

		case actionTypes.PUT_FOOTER_REQUEST:
			return {
				...state,
				putFooter: { ...state.putFooter, loading: true },
			};
		case actionTypes.PUT_FOOTER_SUCCESS:
			return {
				...state,
				putFooter: { loading: false, data: payload },
			};
		case actionTypes.PUT_FOOTER_FAILURE:
			return {
				...state,
				putFooter: { loading: false, data: null },
			};

		case actionTypes.PUT_CONTACT_US_REQUEST:
			return {
				...state,
				putContactUs: { ...state.putContactUs, loading: true },
			};
		case actionTypes.PUT_CONTACT_US_SUCCESS:
			return {
				...state,
				putContactUs: { loading: false, data: payload },
			};
		case actionTypes.PUT_CONTACT_US_FAILURE:
			return {
				...state,
				putContactUs: { loading: false, data: null },
			};

		case actionTypes.PUT_FOR_NEW_CAREER_BANNER_REQUEST:
			return {
				...state,
				putForNewCareerBanner: { ...state.putForNewCareerBanner, loading: true },
			};
		case actionTypes.PUT_FOR_NEW_CAREER_BANNER_SUCCESS:
			return {
				...state,
				putForNewCareerBanner: { loading: false, data: payload },
			};
		case actionTypes.PUT_FOR_NEW_CAREER_BANNER_FAILURE:
			return {
				...state,
				putForNewCareerBanner: { loading: false, data: null },
			};

		case actionTypes.PUT_FOR_NEW_CAREER_SUPPORTED_BY_REQUEST:
			return {
				...state,
				putForNewCareerSupportedBy: { ...state.putForNewCareerSupportedBy, loading: true },
			};
		case actionTypes.PUT_FOR_NEW_CAREER_SUPPORTED_BY_SUCCESS:
			return {
				...state,
				putForNewCareerSupportedBy: { loading: false, data: payload },
			};
		case actionTypes.PUT_FOR_NEW_CAREER_SUPPORTED_BY_FAILURE:
			return {
				...state,
				putForNewCareerSupportedBy: { loading: false, data: null },
			};

		case actionTypes.PUT_FOR_NEW_CAREER_COURSE_FILTER_REQUEST:
			return {
				...state,
				putForNewCareerCourseFilter: { ...state.putForNewCareerCourseFilter, loading: true },
			};
		case actionTypes.PUT_FOR_NEW_CAREER_COURSE_FILTER_SUCCESS:
			return {
				...state,
				putForNewCareerCourseFilter: { loading: false, data: payload },
			};
		case actionTypes.PUT_FOR_NEW_CAREER_COURSE_FILTER_FAILURE:
			return {
				...state,
				putForNewCareerCourseFilter: { loading: false, data: null },
			};

		case actionTypes.PUT_FOR_ENTERPRISE_BANNER_REQUEST:
			return {
				...state,
				putForEnterpriseBanner: { ...state.putForEnterpriseBanner, loading: true },
			};
		case actionTypes.PUT_FOR_ENTERPRISE_BANNER_SUCCESS:
			return {
				...state,
				putForEnterpriseBanner: { loading: false, data: payload },
			};
		case actionTypes.PUT_FOR_ENTERPRISE_BANNER_FAILURE:
			return {
				...state,
				putForEnterpriseBanner: { loading: false, data: null },
			};

		case actionTypes.PUT_FOR_ENTERPRISE_SUPPORTED_BY_REQUEST:
			return {
				...state,
				putForEnterpriseSupportedBy: { ...state.putForEnterpriseSupportedBy, loading: true },
			};
		case actionTypes.PUT_FOR_ENTERPRISE_SUPPORTED_BY_SUCCESS:
			return {
				...state,
				putForEnterpriseSupportedBy: { loading: false, data: payload },
			};
		case actionTypes.PUT_FOR_ENTERPRISE_SUPPORTED_BY_FAILURE:
			return {
				...state,
				putForEnterpriseSupportedBy: { loading: false, data: null },
			};

		case actionTypes.PUT_FOR_ENTERPRISE_COURSE_FILTER_REQUEST:
			return {
				...state,
				putForEnterpriseCourseFilter: { ...state.putForEnterpriseCourseFilter, loading: true },
			};
		case actionTypes.PUT_FOR_ENTERPRISE_COURSE_FILTER_SUCCESS:
			return {
				...state,
				putForEnterpriseCourseFilter: { loading: false, data: payload },
			};
		case actionTypes.PUT_FOR_ENTERPRISE_COURSE_FILTER_FAILURE:
			return {
				...state,
				putForEnterpriseCourseFilter: { loading: false, data: null },
			};

		case actionTypes.PUT_FOR_UNIVERSITIES_BANNER_REQUEST:
			return {
				...state,
				putForUniversitiesBanner: { ...state.putForUniversitiesBanner, loading: true },
			};
		case actionTypes.PUT_FOR_UNIVERSITIES_BANNER_SUCCESS:
			return {
				...state,
				putForUniversitiesBanner: { loading: false, data: payload },
			};
		case actionTypes.PUT_FOR_UNIVERSITIES_BANNER_FAILURE:
			return {
				...state,
				putForUniversitiesBanner: { loading: false, data: null },
			};

		case actionTypes.PUT_FOR_UNIVERSITIES_SUPPORTED_BY_REQUEST:
			return {
				...state,
				putForUniversitiesSupportedBy: { ...state.putForUniversitiesSupportedBy, loading: true },
			};
		case actionTypes.PUT_FOR_UNIVERSITIES_SUPPORTED_BY_SUCCESS:
			return {
				...state,
				putForUniversitiesSupportedBy: { loading: false, data: payload },
			};
		case actionTypes.PUT_FOR_UNIVERSITIES_SUPPORTED_BY_FAILURE:
			return {
				...state,
				putForUniversitiesSupportedBy: { loading: false, data: null },
			};

		case actionTypes.PUT_FOR_UNIVERSITIES_COURSE_FILTER_REQUEST:
			return {
				...state,
				putForUniversitiesCourseFilter: { ...state.putForUniversitiesCourseFilter, loading: true },
			};
		case actionTypes.PUT_FOR_UNIVERSITIES_COURSE_FILTER_SUCCESS:
			return {
				...state,
				putForUniversitiesCourseFilter: { loading: false, data: payload },
			};
		case actionTypes.PUT_FOR_UNIVERSITIES_COURSE_FILTER_FAILURE:
			return {
				...state,
				putForUniversitiesCourseFilter: { loading: false, data: null },
			};

		case actionTypes.GET_HOME_BANNER_REQUEST:
			return {
				...state,
				getHomeBanner: { ...state.getHomeBanner, loading: true },
			};
		case actionTypes.GET_HOME_BANNER_SUCCESS:
			return {
				...state,
				getHomeBanner: { loading: false, data: payload },
			};
		case actionTypes.GET_HOME_BANNER_FAILURE:
			return {
				...state,
				getHomeBanner: { loading: false, data: null },
			};

		case actionTypes.GET_HOME_SKILL_REQUEST:
			return {
				...state,
				getHomeSkill: { ...state.getHomeSkill, loading: true },
			};
		case actionTypes.GET_HOME_SKILL_SUCCESS:
			return {
				...state,
				getHomeSkill: { loading: false, data: payloadByElementIdData(payload) },
			};
		case actionTypes.GET_HOME_SKILL_FAILURE:
			return {
				...state,
				getHomeSkill: { loading: false, data: null },
			};

		case actionTypes.GET_HOME_WHY_US_REQUEST:
			return {
				...state,
				getHomeWhyUs: { ...state.getHomeWhyUs, loading: true },
			};
		case actionTypes.GET_HOME_WHY_US_SUCCESS:
			return {
				...state,
				getHomeWhyUs: { loading: false, data: payload },
			};
		case actionTypes.GET_HOME_WHY_US_FAILURE:
			return {
				...state,
				getHomeWhyUs: { loading: false, data: null },
			};

		case actionTypes.GET_HOME_POPULAR_REQUEST:
			return {
				...state,
				getHomePopular: { ...state.getHomePopular, loading: true },
			};
		case actionTypes.GET_HOME_POPULAR_SUCCESS:
			return {
				...state,
				getHomePopular: { loading: false, data: payload },
			};
		case actionTypes.GET_HOME_POPULAR_FAILURE:
			return {
				...state,
				getHomePopular: { loading: false, data: null },
			};

		case actionTypes.GET_HOME_POPULAR_JOURNEY_REQUEST:
			return {
				...state,
				getHomePopularJourney: { ...state.getHomePopularJourney, loading: true },
			};
		case actionTypes.GET_HOME_POPULAR_JOURNEY_SUCCESS:
			return {
				...state,
				getHomePopularJourney: { loading: false, data: payload },
			};
		case actionTypes.GET_HOME_POPULAR_JOURNEY_FAILURE:
			return {
				...state,
				getHomePopularJourney: { loading: false, data: null },
			};

		case actionTypes.GET_HOME_ENDORSEMENT_REQUEST:
			return {
				...state,
				getHomeEndorsement: { ...state.getHomeEndorsement, loading: true },
			};
		case actionTypes.GET_HOME_ENDORSEMENT_SUCCESS:
			const homeEndorsementByElementIdData = payload => {
				const elementIds = [];
				const data = {};
				const _payload = {};

				if (payload?.length > 0) {
					for (let el of payload) {
						elementIds.push(el.elementId);
					}

					for (let el of new Set([...elementIds])) {
						for (let j of payload) {
							if (j.segment === 'Title') {
								_payload['title'] = { ...j };
							} else {
								if (j.elementId === el) {
									const list = data[el];

									if (list) {
										data[el] = [...data[el], j];
									} else {
										data[el] = [j];
									}
								}
							}
						}
					}
				}

				const greatest =
					Number(
						Object.keys(data)
							.sort((a, b) => a - b)
							.reverse()[0],
					) + 1;

				_payload['data'] = data;
				_payload['title'] = { ..._payload['title'], elementId: greatest };

				return _payload;
			};

			return {
				...state,
				getHomeEndorsement: { loading: false, data: homeEndorsementByElementIdData(payload) },
			};
		case actionTypes.GET_HOME_ENDORSEMENT_FAILURE:
			return {
				...state,
				getHomeEndorsement: { loading: false, data: null },
			};

		case actionTypes.GET_HOME_SUPPORTED_BY_REQUEST:
			return {
				...state,
				getHomeSupportedBy: { ...state.getHomeSupportedBy, loading: true },
			};
		case actionTypes.GET_HOME_SUPPORTED_BY_SUCCESS:
			return {
				...state,
				getHomeSupportedBy: { loading: false, data: payload },
			};
		case actionTypes.GET_HOME_SUPPORTED_BY_FAILURE:
			return {
				...state,
				getHomeSupportedBy: { loading: false, data: null },
			};

		case actionTypes.GET_HOME_JOIN_US_REQUEST:
			return {
				...state,
				getHomeJoinUs: { ...state.getHomeJoinUs, loading: true },
			};
		case actionTypes.GET_HOME_JOIN_US_SUCCESS:
			return {
				...state,
				getHomeJoinUs: { loading: false, data: payload },
			};
		case actionTypes.GET_HOME_JOIN_US_FAILURE:
			return {
				...state,
				getHomeJoinUs: { loading: false, data: null },
			};

		case actionTypes.GET_FOOTER_REQUEST:
			return {
				...state,
				getFooter: { ...state.getFooter, loading: true },
			};
		case actionTypes.GET_FOOTER_SUCCESS:
			return {
				...state,
				getFooter: { loading: false, data: payload },
			};
		case actionTypes.GET_FOOTER_FAILURE:
			return {
				...state,
				getFooter: { loading: false, data: null },
			};

		case actionTypes.GET_CONTACT_US_REQUEST:
			return {
				...state,
				getContactUs: { ...state.getContactUs, loading: true },
			};
		case actionTypes.GET_CONTACT_US_SUCCESS:
			return {
				...state,
				getContactUs: { loading: false, data: payload },
			};
		case actionTypes.GET_CONTACT_US_FAILURE:
			return {
				...state,
				getContactUs: { loading: false, data: null },
			};

		case actionTypes.GET_FOR_NEW_CAREER_BANNER_REQUEST:
			return {
				...state,
				getForNewCareerBanner: { ...state.getForNewCareerBanner, loading: true },
			};
		case actionTypes.GET_FOR_NEW_CAREER_BANNER_SUCCESS:
			return {
				...state,
				getForNewCareerBanner: { loading: false, data: payload },
			};
		case actionTypes.GET_FOR_NEW_CAREER_BANNER_FAILURE:
			return {
				...state,
				getForNewCareerBanner: { loading: false, data: null },
			};

		case actionTypes.GET_FOR_NEW_CAREER_SUPPORTED_BY_REQUEST:
			return {
				...state,
				getForNewCareerSupportedBy: { ...state.getForNewCareerSupportedBy, loading: true },
			};
		case actionTypes.GET_FOR_NEW_CAREER_SUPPORTED_BY_SUCCESS:
			return {
				...state,
				getForNewCareerSupportedBy: { loading: false, data: payload },
			};
		case actionTypes.GET_FOR_NEW_CAREER_SUPPORTED_BY_FAILURE:
			return {
				...state,
				getForNewCareerSupportedBy: { loading: false, data: null },
			};

		case actionTypes.GET_FOR_NEW_CAREER_COURSE_FILTER_REQUEST:
			return {
				...state,
				getForNewCareerCourseFilter: { ...state.getForNewCareerCourseFilter, loading: true },
			};
		case actionTypes.GET_FOR_NEW_CAREER_COURSE_FILTER_SUCCESS:
			return {
				...state,
				getForNewCareerCourseFilter: { loading: false, data: payload },
			};
		case actionTypes.GET_FOR_NEW_CAREER_COURSE_FILTER_FAILURE:
			return {
				...state,
				getForNewCareerCourseFilter: { loading: false, data: null },
			};

		case actionTypes.GET_FOR_ENTERPRISE_BANNER_REQUEST:
			return {
				...state,
				getForEnterpriseBanner: { ...state.getForEnterpriseBanner, loading: true },
			};
		case actionTypes.GET_FOR_ENTERPRISE_BANNER_SUCCESS:
			return {
				...state,
				getForEnterpriseBanner: { loading: false, data: payload },
			};
		case actionTypes.GET_FOR_ENTERPRISE_BANNER_FAILURE:
			return {
				...state,
				getForEnterpriseBanner: { loading: false, data: null },
			};

		case actionTypes.GET_FOR_ENTERPRISE_SUPPORTED_BY_REQUEST:
			return {
				...state,
				getForEnterpriseSupportedBy: { ...state.getForEnterpriseSupportedBy, loading: true },
			};
		case actionTypes.GET_FOR_ENTERPRISE_SUPPORTED_BY_SUCCESS:
			return {
				...state,
				getForEnterpriseSupportedBy: { loading: false, data: payload },
			};
		case actionTypes.GET_FOR_ENTERPRISE_SUPPORTED_BY_FAILURE:
			return {
				...state,
				getForEnterpriseSupportedBy: { loading: false, data: null },
			};

		case actionTypes.GET_FOR_ENTERPRISE_COURSE_FILTER_REQUEST:
			return {
				...state,
				getForEnterpriseCourseFilter: { ...state.getForEnterpriseCourseFilter, loading: true },
			};
		case actionTypes.GET_FOR_ENTERPRISE_COURSE_FILTER_SUCCESS:
			return {
				...state,
				getForEnterpriseCourseFilter: { loading: false, data: payload },
			};
		case actionTypes.GET_FOR_ENTERPRISE_COURSE_FILTER_FAILURE:
			return {
				...state,
				getForEnterpriseCourseFilter: { loading: false, data: null },
			};

		case actionTypes.GET_FOR_UNIVERSITIES_BANNER_REQUEST:
			return {
				...state,
				getForUniversitiesBanner: { ...state.getForUniversitiesBanner, loading: true },
			};
		case actionTypes.GET_FOR_UNIVERSITIES_BANNER_SUCCESS:
			return {
				...state,
				getForUniversitiesBanner: { loading: false, data: payload },
			};
		case actionTypes.GET_FOR_UNIVERSITIES_BANNER_FAILURE:
			return {
				...state,
				getForUniversitiesBanner: { loading: false, data: null },
			};

		case actionTypes.GET_FOR_UNIVERSITIES_SUPPORTED_BY_REQUEST:
			return {
				...state,
				getForUniversitiesSupportedBy: { ...state.getForUniversitiesSupportedBy, loading: true },
			};
		case actionTypes.GET_FOR_UNIVERSITIES_SUPPORTED_BY_SUCCESS:
			return {
				...state,
				getForUniversitiesSupportedBy: { loading: false, data: payload },
			};
		case actionTypes.GET_FOR_UNIVERSITIES_SUPPORTED_BY_FAILURE:
			return {
				...state,
				getForUniversitiesSupportedBy: { loading: false, data: null },
			};

		case actionTypes.GET_FOR_UNIVERSITIES_COURSE_FILTER_REQUEST:
			return {
				...state,
				getForUniversitiesCourseFilter: { ...state.getForUniversitiesCourseFilter, loading: true },
			};
		case actionTypes.GET_FOR_UNIVERSITIES_COURSE_FILTER_SUCCESS:
			return {
				...state,
				getForUniversitiesCourseFilter: { loading: false, data: payload },
			};
		case actionTypes.GET_FOR_UNIVERSITIES_COURSE_FILTER_FAILURE:
			return {
				...state,
				getForUniversitiesCourseFilter: { loading: false, data: null },
			};

		case actionTypes.PUT_ORG_REQUEST:
			return {
				...state,
				putOrg: { ...state.putOrg, loading: true },
			};
		case actionTypes.PUT_ORG_SUCCESS:
			return {
				...state,
				putOrg: { loading: false, data: payload },
			};
		case actionTypes.PUT_ORG_FAILURE:
			return {
				...state,
				putOrg: { loading: false, data: null },
			};

		case actionTypes.GET_ORG_REQUEST:
			return {
				...state,
				getOrg: { ...state.getOrg, loading: true },
			};
		case actionTypes.GET_ORG_SUCCESS:
			return {
				...state,
				getOrg: { loading: false, data: payload },
			};
		case actionTypes.GET_ORG_FAILURE:
			return {
				...state,
				getOrg: { loading: false, data: null },
			};

		case actionTypes.PUT_ABOUT_REQUEST:
			return {
				...state,
				putAbout: { ...state.putAbout, loading: true },
			};
		case actionTypes.PUT_ABOUT_SUCCESS:
			return {
				...state,
				putAbout: { loading: false, data: payload },
			};
		case actionTypes.PUT_ABOUT_FAILURE:
			return {
				...state,
				putAbout: { loading: false, data: null },
			};

		case actionTypes.GET_ABOUT_REQUEST:
			return {
				...state,
				getAbout: { ...state.getAbout, loading: true },
			};
		case actionTypes.GET_ABOUT_SUCCESS:
			return {
				...state,
				getAbout: { loading: false, data: payload },
			};
		case actionTypes.GET_ABOUT_FAILURE:
			return {
				...state,
				getAbout: { loading: false, data: null },
			};

		case actionTypes.PUT_HEADER_REQUEST:
			return {
				...state,
				putHeader: { ...state.putHeader, loading: true },
			};
		case actionTypes.PUT_HEADER_SUCCESS:
			return {
				...state,
				putHeader: { loading: false, data: payload },
			};
		case actionTypes.PUT_HEADER_FAILURE:
			return {
				...state,
				putHeader: { loading: false, data: null },
			};

		case actionTypes.GET_HEADER_REQUEST:
			return {
				...state,
				getHeader: { ...state.getHeader, loading: true },
			};
		case actionTypes.GET_HEADER_SUCCESS:
			return {
				...state,
				getHeader: { loading: false, data: payload },
			};
		case actionTypes.GET_HEADER_FAILURE:
			return {
				...state,
				getHeader: { loading: false, data: null },
			};

		default:
			return state;
	}
};
