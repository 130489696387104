import axios from 'axios';
import { toast } from 'react-toastify';
import history from './history';
import * as storage from './localStorage';

const defaultOptions = {
	headers: {},
	data: {},
	needToken: false,
};

const ApiCaller = (options = defaultOptions) => {
	const axiosInstance = axios.create({
		headers: {
			...options?.headers,
		},
		data: {
			...options?.data,
		},
		responseType: 'json',
	});

	axiosInstance.interceptors.request.use(
		async config => {
			const tokenInfo = storage.getLocalStorage('tokenInfo');

			if (options && options.needToken && !tokenInfo) {
				history.replace('/login');
				return false;
			}

			if (options && options.needToken && tokenInfo) {
				config.headers['Authorization'] = `${tokenInfo.type} ${tokenInfo.token}`;
			}

			return config;
		},
		error => {
			return Promise.reject(error);
		},
	);

	axiosInstance.interceptors.response.use(
		response => {
			return response;
		},
		error => {
			const { response } = error;

			if (response && response.status && response.status === 401) {
				toast.dismiss();
				toast.error('Unauthorized! please login again.');
				storage.clearLocalStorage('tokenInfo');
				history.push('/login');
			} else if (response && response.status && response.status === 403) {
			} else if (response && response.status && response.status >= 500) {
			}

			return Promise.reject(error);
		},
	);

	return axiosInstance;
};

export default ApiCaller;
