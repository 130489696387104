import React from 'react';
import DashboardLayout from '../layout/dashboard';
import PublicLayout from '../layout/public';

const Login = React.lazy(() => import('../containers/login'));
const Page404 = React.lazy(() => import('../containers/404'));
const Page500 = React.lazy(() => import('../containers/500'));

const Home = React.lazy(() => import('../containers/home'));

const Users = React.lazy(() => import('../containers/users'));
const User = React.lazy(() => import('../containers/users/details'));
const CreateUser = React.lazy(() => import('../containers/users/create'));

const Instructors = React.lazy(() => import('../containers/instructors'));

const Gamification = React.lazy(() => import('../containers/gamification'));
const BadgeSettings = React.lazy(() => import('../containers/gamification/badgeSettings'));

const Subjects = React.lazy(() => import('../containers/subjects'));
const Subject = React.lazy(() => import('../containers/subjects/details'));
const CreateSubject = React.lazy(() => import('../containers/subjects/create'));

const Courses = React.lazy(() => import('../containers/courses'));
const Course = React.lazy(() => import('../containers/courses/details'));

const Journeys = React.lazy(() => import('../containers/journeys'));
const Journey = React.lazy(() => import('../containers/journeys/details'));

const Comments = React.lazy(() => import('../containers/comments'));
const Comment = React.lazy(() => import('../containers/comments/details'));

const LibraryOfContent = React.lazy(() => import('../containers/libraryOfContent'));

const QuizAttempts = React.lazy(() => import('../containers/quizAttempts'));

const AssignmentAttempts = React.lazy(() => import('../containers/assignmentAttempts'));
const AssignmentAttempt = React.lazy(() => import('../containers/assignmentAttempts/details'));

const ContentManagement = React.lazy(() => import('../containers/contentManagement'));

const ContactUs = React.lazy(() => import('../containers/contactUs'));
const NewsLetterSubscription = React.lazy(() => import('../containers/newsSubscription'));

const Profile = React.lazy(() => import('../containers/profile'));
const ChangePassword = React.lazy(() => import('../containers/profile/changePassword'));

const Analytics = React.lazy(() => import('../containers/analytics'));
const InnerChartLineAnalytic = React.lazy(() => import('../containers/analytics/innerChartLine'));

const MyResources = React.lazy(() => import('../containers/myResources'));

const Notifications = React.lazy(() => import('../containers/notifications'));
const NotificationSettings = React.lazy(() => import('../containers/notifications/settings'));
const SendNotification = React.lazy(() => import('../containers/notifications/send'));

const routes = [
	{
		path: '/404',
		exact: true,
		name: 'Page 404',
		element: Page404,
		layout: PublicLayout,
	},
	{
		path: '/500',
		exact: true,
		name: 'Page 500',
		element: Page500,
		layout: PublicLayout,
	},
	{
		path: '/login',
		exact: true,
		name: 'Login Page',
		element: Login,
		layout: PublicLayout,
	},
	{
		path: '/',
		exact: true,
		name: '',
		element: Home,
		layout: DashboardLayout,
	},
	{
		path: '/users',
		exact: true,
		name: 'Users',
		element: Users,
		layout: DashboardLayout,
	},
	{
		path: '/user/:userId',
		exact: true,
		name: 'Users',
		element: User,
		layout: DashboardLayout,
	},
	{
		path: '/create-user',
		exact: true,
		name: 'Create User',
		element: CreateUser,
		layout: DashboardLayout,
	},
	{
		path: '/points',
		exact: true,
		name: 'Points',
		element: Gamification,
		layout: DashboardLayout,
	},
	{
		path: '/subjects',
		exact: true,
		name: 'Subjects',
		element: Subjects,
		layout: DashboardLayout,
	},
	{
		path: '/subject/:id/',
		exact: true,
		name: 'Subject',
		element: Subject,
		layout: DashboardLayout,
	},
	{
		path: '/subject/:id/:parentId',
		exact: true,
		name: 'Subject',
		element: Subject,
		layout: DashboardLayout,
	},
	{
		path: '/create-subject',
		exact: true,
		name: 'Create subject',
		element: CreateSubject,
		layout: DashboardLayout,
	},
	{
		path: '/create-subject/:parentId',
		exact: true,
		name: 'Create subject',
		element: CreateSubject,
		layout: DashboardLayout,
	},
	{
		path: '/instructors',
		exact: true,
		name: 'Instructors',
		element: Instructors,
		layout: DashboardLayout,
	},
	{
		path: '/courses',
		exact: true,
		name: 'courses',
		element: Courses,
		layout: DashboardLayout,
	},
	{
		path: '/course/:courseId/',
		exact: true,
		name: 'Course',
		element: Course,
		layout: DashboardLayout,
	},
	{
		path: '/journeys',
		exact: true,
		name: 'Journeys',
		element: Journeys,
		layout: DashboardLayout,
	},
	{
		path: '/journey/:journeyId',
		exact: true,
		name: 'Journey',
		element: Journey,
		layout: DashboardLayout,
	},
	{
		path: '/comments',
		exact: true,
		name: 'Comments',
		element: Comments,
		layout: DashboardLayout,
	},
	{
		path: '/comment/:commentId',
		exact: true,
		name: 'Comment',
		element: Comment,
		layout: DashboardLayout,
	},
	{
		path: '/library-of-content',
		exact: true,
		name: 'Library of content',
		element: LibraryOfContent,
		layout: DashboardLayout,
	},
	{
		path: '/assignment-attempts',
		exact: true,
		name: 'Assignment attempts',
		element: AssignmentAttempts,
		layout: DashboardLayout,
	},
	{
		path: '/assignment-attempt/:assignmentId',
		exact: true,
		name: 'Assignment attempt',
		element: AssignmentAttempt,
		layout: DashboardLayout,
	},
	{
		path: '/quiz-attempts',
		exact: true,
		name: 'Quiz attempts',
		element: QuizAttempts,
		layout: DashboardLayout,
	},
	{
		path: '/content-management',
		exact: true,
		name: 'Content management',
		element: ContentManagement,
		layout: DashboardLayout,
	},
	{
		path: '/contact-us',
		exact: true,
		name: 'Contact us',
		element: ContactUs,
		layout: DashboardLayout,
	},
	{
		path: '/news-subscription',
		exact: true,
		name: 'News subscription',
		element: NewsLetterSubscription,
		layout: DashboardLayout,
	},
	{
		path: '/profile',
		exact: true,
		name: 'Profile',
		element: Profile,
		layout: DashboardLayout,
	},
	{
		path: '/change-password',
		exact: true,
		name: 'Change password',
		element: ChangePassword,
		layout: DashboardLayout,
	},
	{
		path: '/analytics',
		exact: true,
		name: 'Analytics',
		element: Analytics,
		layout: DashboardLayout,
	},
	{
		path: '/analytics/inner-chart-line',
		exact: true,
		name: 'Analytics',
		element: InnerChartLineAnalytic,
		layout: DashboardLayout,
	},
	{
		path: '/my-resources',
		exact: true,
		name: 'My resources',
		element: MyResources,
		layout: DashboardLayout,
	},
	{
		path: '/notifications',
		exact: true,
		name: 'Notifications',
		element: Notifications,
		layout: DashboardLayout,
	},
	{
		path: '/notification-settings',
		exact: true,
		name: 'Notification settings',
		element: NotificationSettings,
		layout: DashboardLayout,
	},
	{
		path: '/send-notification',
		exact: true,
		name: 'Send Notification',
		element: SendNotification,
		layout: DashboardLayout,
	},
	{
		path: 'badge-settings',
		exact: true,
		name: 'Badge Settings',
		element: BadgeSettings,
		layout: DashboardLayout,
	},
];

export default routes;
