import * as actionTypes from '../constants/actionTypes';

const initialState = {
	allJourneys: {
		loading: false,
		data: null,
	},
	journeysFilter: {
		page: 0,
		size: 6,
	},
	journeyById: {
		loading: false,
		data: null,
	},
	studentsOfJourney: {
		loading: false,
		data: null,
	},
	visitorsMetricsOfJourney: {
		loading: false,
		data: null,
	},
	newStudentsMetricsOfJourney: {
		loading: false,
		data: null,
	},
	putJourneyStatus: {
		loading: false,
		data: null,
	},
};

const Journey = (state = initialState, { type, payload }) => {
	switch (type) {
		case actionTypes.GET_ALL_JOURNEYS_REQUEST:
			return {
				...state,
				allJourneys: {
					...state.allJourneys,
					loading: true,
				},
			};
		case actionTypes.GET_ALL_JOURNEYS_SUCCESS:
			return {
				...state,
				allJourneys: {
					loading: false,
					data: payload,
				},
			};
		case actionTypes.GET_ALL_JOURNEYS_FAILURE:
			return {
				...state,
				allJourneys: {
					loading: false,
					data: null,
				},
			};

		case actionTypes.SET_JOURNEYS_FILTER:
			return {
				...state,
				journeysFilter: {
					...state.journeysFilter,
					...payload,
				},
			};

		case actionTypes.GET_JOURNEY_BY_ID_REQUEST:
			return {
				...state,
				journeyById: {
					...state.journeyById,
					loading: true,
				},
			};
		case actionTypes.GET_JOURNEY_BY_ID_SUCCESS:
			return {
				...state,
				journeyById: {
					loading: false,
					data: payload,
				},
			};
		case actionTypes.GET_JOURNEY_BY_ID_FAILURE:
			return {
				...state,
				journeyById: {
					loading: false,
					data: null,
				},
			};

		case actionTypes.GET_STUDENTS_OF_JOURNEY_REQUEST:
			return {
				...state,
				studentsOfJourney: {
					...state.studentsOfJourney,
					loading: true,
				},
			};
		case actionTypes.GET_STUDENTS_OF_JOURNEY_SUCCESS:
			return {
				...state,
				studentsOfJourney: {
					loading: false,
					data: payload,
				},
			};
		case actionTypes.GET_STUDENTS_OF_JOURNEY_FAILURE:
			return {
				...state,
				studentsOfJourney: {
					loading: false,
					data: null,
				},
			};

		case actionTypes.GET_VISITORS_METRICS_OF_JOURNEY_REQUEST:
			return {
				...state,
				visitorsMetricsOfJourney: {
					...state.visitorsMetricsOfJourney,
					loading: true,
				},
			};
		case actionTypes.GET_VISITORS_METRICS_OF_JOURNEY_SUCCESS:
			return {
				...state,
				visitorsMetricsOfJourney: {
					loading: false,
					data: payload,
				},
			};
		case actionTypes.GET_VISITORS_METRICS_OF_JOURNEY_FAILURE:
			return {
				...state,
				visitorsMetricsOfJourney: {
					loading: false,
					data: null,
				},
			};

		case actionTypes.GET_NEW_STUDENTS_METRICS_OF_JOURNEY_REQUEST:
			return {
				...state,
				newStudentsMetricsOfJourney: {
					...state.newStudentsMetricsOfJourney,
					loading: true,
				},
			};
		case actionTypes.GET_NEW_STUDENTS_METRICS_OF_JOURNEY_SUCCESS:
			return {
				...state,
				newStudentsMetricsOfJourney: {
					loading: false,
					data: payload,
				},
			};
		case actionTypes.GET_NEW_STUDENTS_METRICS_OF_JOURNEY_FAILURE:
			return {
				...state,
				newStudentsMetricsOfJourney: {
					loading: false,
					data: null,
				},
			};

		case actionTypes.PUT_JOURNEY_STATUS_REQUEST:
			return {
				...state,
				putJourneyStatus: { ...state.putJourneyStatus, loading: true },
			};
		case actionTypes.PUT_JOURNEY_STATUS_SUCCESS:
			return {
				...state,
				putJourneyStatus: { loading: false, data: payload },
			};
		case actionTypes.PUT_JOURNEY_STATUS_FAILURE:
			return {
				...state,
				putJourneyStatus: { loading: false, data: null },
			};

		default:
			return state;
	}
};

export default Journey;
