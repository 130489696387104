import { configureStore } from '@reduxjs/toolkit';
import rootReducer from '../reducers';

const configure_store = initialState => {
	const store = configureStore({
		reducer: rootReducer(),
		initialState,
		middleware: getDefaultMiddleware => {
			return getDefaultMiddleware({ thunk: true });
		},
		devTools: true,
	});

	return store;
};

export default configure_store;
