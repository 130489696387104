/**
 * Get form data from json.
 * @param json {Object} {page: 0, size: 10, ...others}
 * @example const formData = convertJsonToQueryString({page: 0, size: 10, ...others})
 */

export default json => {
	let formData = new FormData();
	Object.keys(json).forEach((item, index) => {
		if (json[item] !== '') {
			formData.append(item, json[item]);
		}
	});
	return formData;
};
