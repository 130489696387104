import * as actionTypes from '../constants/actionTypes';
import { DOWNLOAD_USERS_FAILURE, DOWNLOAD_USERS_REQUEST, DOWNLOAD_USERS_SUCCESS } from '../constants/actionTypes';

const initialState = {
	userStatusList: {
		loading: false,
		data: null,
	},
	usersFilter: {
		page: 0,
		size: 6,
	},
	instructorsFilter: {
		page: 0,
		size: 6,
		userType: 'instructor',
	},
	userList: {
		loading: false,
		data: null,
	},
	createUser: {
		loading: false,
		data: null,
	},
	userById: {
		loading: false,
		data: null,
	},
	changeUserStatus: {
		loading: false,
		data: null,
	},
	putUserById: {
		loading: false,
		data: null,
	},
	userRoles: {
		loading: false,
		data: null,
	},
	putUserRole: {
		loading: false,
		data: null,
	},
	downloadUsers: {
		loading: false,
		data: null,
	},
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case actionTypes.GET_USER_STATUS_LIST_REQUEST:
			return {
				...state,
				userStatusList: {
					loading: true,
				},
			};
		case actionTypes.GET_USER_STATUS_LIST_SUCCESS:
			return {
				...state,
				userStatusList: {
					loading: false,
					data: payload,
				},
			};
		case actionTypes.GET_USER_STATUS_LIST_FAILURE:
			return {
				...state,
				userStatusList: {
					loading: false,
					data: null,
				},
			};

		case actionTypes.SET_USERS_FILTER:
			return {
				...state,
				usersFilter: {
					...state.usersFilter,
					...payload,
				},
			};

		case actionTypes.SET_INSTRUCTORS_FILTER:
			return {
				...state,
				instructorsFilter: {
					...state.instructorsFilter,
					...payload,
				},
			};

		case actionTypes.GET_USER_LIST_REQUEST:
			return {
				...state,
				userList: {
					...state.userList,
					loading: true,
				},
			};
		case actionTypes.GET_USER_LIST_SUCCESS:
			return {
				...state,
				userList: {
					loading: false,
					data: payload,
				},
			};
		case actionTypes.GET_USER_LIST_FAILURE:
			return {
				...state,
				userList: {
					loading: false,
					data: null,
				},
			};

		case actionTypes.CREATE_USER_REQUEST:
			return {
				...state,
				createUser: {
					loading: true,
				},
			};
		case actionTypes.CREATE_USER_SUCCESS:
			return {
				...state,
				createUser: {
					loading: false,
					data: payload,
				},
			};
		case actionTypes.CREATE_USER_FAILURE:
			return {
				...state,
				createUser: {
					loading: false,
					data: null,
				},
			};

		case actionTypes.GET_USER_REQUEST:
			return {
				...state,
				userById: {
					loading: true,
				},
			};
		case actionTypes.GET_USER_SUCCESS:
			return {
				...state,
				userById: {
					loading: false,
					data: payload,
				},
			};
		case actionTypes.GET_USER_FAILURE:
			return {
				...state,
				userById: {
					loading: false,
					data: null,
				},
			};

		case actionTypes.CHANGE_USER_STATUS_REQUEST:
			return {
				...state,
				changeUserStatus: {
					loading: true,
				},
			};
		case actionTypes.CHANGE_USER_STATUS_SUCCESS:
			return {
				...state,
				changeUserStatus: {
					loading: false,
					data: payload,
				},
			};
		case actionTypes.CHANGE_USER_STATUS_FAILURE:
			return {
				...state,
				changeUserStatus: {
					loading: false,
					data: null,
				},
			};

		case actionTypes.PUT_USER_REQUEST:
			return {
				...state,
				putUserById: {
					loading: true,
				},
			};
		case actionTypes.PUT_USER_SUCCESS:
			return {
				...state,
				putUserById: {
					loading: false,
					data: payload,
				},
			};
		case actionTypes.PUT_USER_FAILURE:
			return {
				...state,
				putUserById: {
					loading: false,
					data: null,
				},
			};

		case actionTypes.GET_USER_ROLES_REQUEST:
			return {
				...state,
				userRoles: {
					loading: true,
				},
			};
		case actionTypes.GET_USER_ROLES_SUCCESS:
			return {
				...state,
				userRoles: {
					loading: false,
					data: payload,
				},
			};
		case actionTypes.GET_USER_ROLES_FAILURE:
			return {
				...state,
				userRoles: {
					loading: false,
					data: null,
				},
			};

		case actionTypes.PUT_USER_ROLE_REQUEST:
			return {
				...state,
				putUserRole: {
					loading: true,
				},
			};
		case actionTypes.PUT_USER_ROLE_SUCCESS:
			return {
				...state,
				putUserRole: {
					loading: false,
					data: payload,
				},
			};
		case actionTypes.PUT_USER_ROLE_FAILURE:
			return {
				...state,
				putUserRole: {
					loading: false,
					data: null,
				},
			};

		case actionTypes.DOWNLOAD_USERS_REQUEST:
			return {
				...state,
				downloadUsers: { ...state.downloadUsers, loading: true },
			};
		case actionTypes.DOWNLOAD_USERS_SUCCESS:
			return {
				...state,
				downloadUsers: { loading: false, data: payload },
			};
		case actionTypes.DOWNLOAD_USERS_FAILURE:
			return {
				...state,
				downloadUsers: { loading: false, data: null },
			};

		default:
			return state;
	}
};
