import * as actionTypes from '../constants/actionTypes';

const initialState = {
	allCourses: {
		loading: false,
		data: null,
	},
	coursesFilter: {
		page: 0,
		size: 6,
	},
	courseById: {
		loading: false,
		data: null,
	},
	studentsOfCourse: {
		loading: false,
		data: null,
	},
	visitorsMetricsOfCourse: {
		loading: false,
		data: null,
	},
	newStudentsMetricsOfCourse: {
		loading: false,
		data: null,
	},
	putCourseStatus: {
		loading: false,
		data: null,
	},
	adminAssignmentAttempts: {
		loading: false,
		data: null,
	},
	adminAssignmentFilter: {
		page: 0,
		size: 6,
	},
	adminAssignmentAttemptById: {
		loading: false,
		data: null,
	},
	adminGradeAssignmentAttempt: {
		loading: false,
		data: null,
	},
	adminQuizAttempts: {
		loading: false,
		data: null,
	},
};

const Course = (state = initialState, { type, payload }) => {
	switch (type) {
		case actionTypes.GET_ALL_COURSES_REQUEST:
			return {
				...state,
				allCourses: {
					...state.allCourses,
					loading: true,
				},
			};
		case actionTypes.GET_ALL_COURSES_SUCCESS:
			return {
				...state,
				allCourses: {
					loading: false,
					data: payload,
				},
			};
		case actionTypes.GET_ALL_COURSES_FAILURE:
			return {
				...state,
				allCourses: {
					loading: false,
					data: null,
				},
			};

		case actionTypes.SET_COURSES_FILTER:
			return {
				...state,
				coursesFilter: {
					...state.coursesFilter,
					...payload,
				},
			};

		case actionTypes.GET_COURSE_BY_ID_REQUEST:
			return {
				...state,
				courseById: {
					...state.courseById,
					loading: true,
				},
			};
		case actionTypes.GET_COURSE_BY_ID_SUCCESS:
			return {
				...state,
				courseById: {
					loading: false,
					data: payload,
				},
			};
		case actionTypes.GET_COURSE_BY_ID_FAILURE:
			return {
				...state,
				courseById: {
					loading: false,
					data: null,
				},
			};

		case actionTypes.GET_STUDENTS_OF_COURSE_REQUEST:
			return {
				...state,
				studentsOfCourse: {
					...state.studentsOfCourse,
					loading: true,
				},
			};
		case actionTypes.GET_STUDENTS_OF_COURSE_SUCCESS:
			return {
				...state,
				studentsOfCourse: {
					loading: false,
					data: payload,
				},
			};
		case actionTypes.GET_STUDENTS_OF_COURSE_FAILURE:
			return {
				...state,
				studentsOfCourse: {
					loading: false,
					data: null,
				},
			};

		case actionTypes.GET_VISITORS_METRICS_OF_COURSE_REQUEST:
			return {
				...state,
				visitorsMetricsOfCourse: {
					...state.visitorsMetricsOfCourse,
					loading: true,
				},
			};
		case actionTypes.GET_VISITORS_METRICS_OF_COURSE_SUCCESS:
			return {
				...state,
				visitorsMetricsOfCourse: {
					loading: false,
					data: payload,
				},
			};
		case actionTypes.GET_VISITORS_METRICS_OF_COURSE_FAILURE:
			return {
				...state,
				visitorsMetricsOfCourse: {
					loading: false,
					data: null,
				},
			};

		case actionTypes.GET_NEW_STUDENTS_METRICS_OF_COURSE_REQUEST:
			return {
				...state,
				newStudentsMetricsOfCourse: {
					...state.newStudentsMetricsOfCourse,
					loading: true,
				},
			};
		case actionTypes.GET_NEW_STUDENTS_METRICS_OF_COURSE_SUCCESS:
			return {
				...state,
				newStudentsMetricsOfCourse: {
					loading: false,
					data: payload,
				},
			};
		case actionTypes.GET_NEW_STUDENTS_METRICS_OF_COURSE_FAILURE:
			return {
				...state,
				newStudentsMetricsOfCourse: {
					loading: false,
					data: null,
				},
			};

		case actionTypes.PUT_COURSE_STATUS_REQUEST:
			return {
				...state,
				putCourseStatus: { ...state.putCourseStatus, loading: true },
			};
		case actionTypes.PUT_COURSE_STATUS_SUCCESS:
			return {
				...state,
				putCourseStatus: { loading: false, data: payload },
			};
		case actionTypes.PUT_COURSE_STATUS_FAILURE:
			return {
				...state,
				putCourseStatus: { loading: false, data: null },
			};

		case actionTypes.GET_ADMIN_ASSIGNMENT_ATTEMPTS_REQUEST:
			return {
				...state,
				adminAssignmentAttempts: { ...state.adminAssignmentAttempts, loading: true },
			};
		case actionTypes.GET_ADMIN_ASSIGNMENT_ATTEMPTS_SUCCESS:
			return {
				...state,
				adminAssignmentAttempts: { loading: false, data: payload },
			};
		case actionTypes.GET_ADMIN_ASSIGNMENT_ATTEMPTS_FAILURE:
			return {
				...state,
				adminAssignmentAttempts: { loading: false, data: null },
			};

		case actionTypes.SET_ADMIN_ASSIGNMENT_ATTEMPTS_FILTER:
			return {
				...state,
				adminAssignmentFilter: {
					...state.adminAssignmentFilter,
					...payload,
				},
			};

		case actionTypes.GET_ADMIN_ASSIGNMENT_ATTEMPT_BY_ID_REQUEST:
			return {
				...state,
				adminAssignmentAttemptById: { ...state.adminAssignmentAttemptById, loading: true },
			};
		case actionTypes.GET_ADMIN_ASSIGNMENT_ATTEMPT_BY_ID_SUCCESS:
			return {
				...state,
				adminAssignmentAttemptById: { loading: false, data: payload },
			};
		case actionTypes.GET_ADMIN_ASSIGNMENT_ATTEMPT_BY_ID_FAILURE:
			return {
				...state,
				adminAssignmentAttemptById: { loading: false, data: null },
			};

		case actionTypes.ADMIN_GRADE_ASSIGNMENT_ATTEMPT_REQUEST:
			return {
				...state,
				adminGradeAssignmentAttempt: { ...state.adminGradeAssignmentAttempt, loading: true },
			};
		case actionTypes.ADMIN_GRADE_ASSIGNMENT_ATTEMPT_SUCCESS:
			return {
				...state,
				adminGradeAssignmentAttempt: { loading: false, data: payload },
			};
		case actionTypes.ADMIN_GRADE_ASSIGNMENT_ATTEMPT_FAILURE:
			return {
				...state,
				adminGradeAssignmentAttempt: { loading: false, data: null },
			};

		case actionTypes.GET_ADMIN_QUIZ_ATTEMPTS_REQUEST:
			return {
				...state,
				adminQuizAttempts: { ...state.adminQuizAttempts, loading: true },
			};
		case actionTypes.GET_ADMIN_QUIZ_ATTEMPTS_SUCCESS:
			return {
				...state,
				adminQuizAttempts: { loading: false, data: payload },
			};
		case actionTypes.GET_ADMIN_QUIZ_ATTEMPTS_FAILURE:
			return {
				...state,
				adminQuizAttempts: { loading: false, data: null },
			};

		default:
			return state;
	}
};

export default Course;
