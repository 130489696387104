import React from 'react';
import {
	CAvatar,
	CDropdown,
	CDropdownDivider,
	CDropdownHeader,
	CDropdownItem,
	CDropdownMenu,
	CDropdownToggle,
} from '@coreui/react';
import { cilLockLocked, cilUser } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import logout from '../../../helpers/logout';
import { Link } from 'react-router-dom';

const AppHeaderDropdown = ({ profileSrc }) => {
	return (
		<CDropdown variant="nav-item">
			<CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
				{profileSrc ? (
					<CAvatar src={profileSrc} size="md" />
				) : (
					<CIcon icon={cilUser} size="lg" style={{ color: 'white' }} />
				)}
			</CDropdownToggle>
			<CDropdownMenu className="pt-0" placement="bottom-end">
				<CDropdownHeader className="bg-light fw-semibold py-2">Account</CDropdownHeader>
				<Link to="/profile">
					<CDropdownItem>
						<CIcon icon={cilUser} className="me-2" />
						Profile
					</CDropdownItem>
				</Link>
				<CDropdownDivider />
				<Link to="/change-password">
					<CDropdownItem>
						<CIcon icon={cilUser} className="me-2" />
						Change password
					</CDropdownItem>
				</Link>
				<CDropdownItem onClick={logout}>
					<CIcon icon={cilLockLocked} className="me-2" />
					Logout
				</CDropdownItem>
			</CDropdownMenu>
		</CDropdown>
	);
};

export default AppHeaderDropdown;
