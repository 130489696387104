import { toast } from 'react-toastify';
import * as actionTypes from '../constants/actionTypes';
import * as profileApi from '../apis/profile';

const getProfileInfoRequest = () => {
	return {
		type: actionTypes.GET_PROFILE_INFO_REQUEST,
	};
};
const getProfileInfoSuccess = payload => {
	return {
		type: actionTypes.GET_PROFILE_INFO_SUCCESS,
		payload,
	};
};
export const getProfileInfoFailure = () => {
	return {
		type: actionTypes.GET_PROFILE_INFO_FAILURE,
	};
};
export const getProfileInfo = () => {
	return async dispatch => {
		await dispatch(getProfileInfoRequest());
		try {
			const response = await profileApi.getProfileInfoService();
			const data = response && response.data;

			await dispatch(getProfileInfoSuccess(data));
		} catch (error) {
			if (error && error.response && error.response.data && error.response.data.message) {
				toast.error(error.response.data.message.toString());
			}
			console.log(error);
			await dispatch(getProfileInfoFailure());
		}
	};
};

const putProfileInfoRequest = () => {
	return {
		type: actionTypes.PUT_PROFILE_INFO_REQUEST,
	};
};
const putProfileInfoSuccess = payload => {
	return {
		type: actionTypes.PUT_PROFILE_INFO_SUCCESS,
		payload,
	};
};
export const putProfileInfoFailure = () => {
	return {
		type: actionTypes.PUT_PROFILE_INFO_FAILURE,
	};
};
export const putProfileInfo = postData => {
	return async dispatch => {
		dispatch(putProfileInfoRequest());
		try {
			const response = await profileApi.putProfileInfoService(postData);
			const data = response && response.data;

			toast.success('Profile updated successfully');
			await dispatch(getProfileInfo());

			dispatch(putProfileInfoSuccess(data));
		} catch (error) {
			if (error && error.response && error.response.data && error.response.data.message) {
				toast.error(error.response.data.message.toString());
			}

			await dispatch(putProfileInfoFailure());
		}
	};
};

const postProfilePictureRequest = () => {
	return {
		type: actionTypes.POST_PROFILE_PICTURE_REQUEST,
	};
};
const postProfilePictureSuccess = payload => {
	return {
		type: actionTypes.POST_PROFILE_PICTURE_SUCCESS,
		payload,
	};
};
export const postProfilePictureFailure = () => {
	return {
		type: actionTypes.POST_PROFILE_PICTURE_FAILURE,
	};
};
export const postProfilePicture = postData => {
	return async dispatch => {
		dispatch(postProfilePictureRequest());
		try {
			const response = await profileApi.postProfilePictureService(postData);
			const data = response && response.data;

			toast.success('Profile picture updated successfully');
			await dispatch(getProfileInfo());

			dispatch(postProfilePictureSuccess(data));
		} catch (error) {
			if (error && error.response && error.response.data && error.response.data.message) {
				toast.error(error.response.data.message.toString());
			}

			await dispatch(postProfilePictureFailure());
		}
	};
};

const changePasswordRequest = () => {
	return {
		type: actionTypes.CHANGE_PASSWORD_REQUEST,
	};
};
const changePasswordSuccess = payload => {
	return {
		type: actionTypes.CHANGE_PASSWORD_SUCCESS,
		payload,
	};
};
export const changePasswordFailure = () => {
	return {
		type: actionTypes.CHANGE_PASSWORD_FAILURE,
	};
};
export const changePassword = postData => {
	return async dispatch => {
		dispatch(changePasswordRequest());
		try {
			const response = await profileApi.changePasswordService(postData);
			const data = response && response.data;

			toast.success('Password changed successfully');
			await dispatch(getProfileInfo());

			dispatch(changePasswordSuccess(data));
		} catch (error) {
			if (error && error.response && error.response.data && error.response.data.message) {
				toast.error(error.response.data.message.toString());
			}

			await dispatch(changePasswordFailure());
		}
	};
};

const getResidencyStatusListRequest = () => {
	return {
		type: actionTypes.GET_RESIDENCY_STATUS_LIST_REQUEST,
	};
};
const getResidencyStatusListSuccess = payload => {
	return {
		type: actionTypes.GET_RESIDENCY_STATUS_LIST_SUCCESS,
		payload,
	};
};
export const getResidencyStatusListFailure = () => {
	return {
		type: actionTypes.GET_RESIDENCY_STATUS_LIST_FAILURE,
	};
};
export const getResidencyStatusList = postData => {
	return async dispatch => {
		dispatch(getResidencyStatusListRequest());
		try {
			const response = await profileApi.getResidencyStatusListService(postData);
			const data = response && response.data;

			await dispatch(getResidencyStatusListSuccess(data));
		} catch (error) {
			if (error && error.response && error.response.data && error.response.data.message) {
				toast.error(error.response.data.message.toString());
			}

			await dispatch(getResidencyStatusListFailure());
		}
	};
};

const getGenderListRequest = () => {
	return {
		type: actionTypes.GET_GENDER_LIST_REQUEST,
	};
};
const getGenderListSuccess = payload => {
	return {
		type: actionTypes.GET_GENDER_LIST_SUCCESS,
		payload,
	};
};
export const getGenderListFailure = () => {
	return {
		type: actionTypes.GET_GENDER_LIST_FAILURE,
	};
};
export const getGenderList = postData => {
	return async dispatch => {
		dispatch(getGenderListRequest());
		try {
			const response = await profileApi.getGenderListService(postData);
			const data = response && response.data;

			await dispatch(getGenderListSuccess(data));
		} catch (error) {
			if (error && error.response && error.response.data && error.response.data.message) {
				toast.error(error.response.data.message.toString());
			}

			await dispatch(getGenderListFailure());
		}
	};
};

const getSpecialGroupListRequest = () => {
	return {
		type: actionTypes.GET_SPECIAL_GROUP_LIST_REQUEST,
	};
};
const getSpecialGroupListSuccess = payload => {
	return {
		type: actionTypes.GET_SPECIAL_GROUP_LIST_SUCCESS,
		payload,
	};
};
export const getSpecialGroupListFailure = () => {
	return {
		type: actionTypes.GET_SPECIAL_GROUP_LIST_FAILURE,
	};
};
export const getSpecialGroupList = postData => {
	return async dispatch => {
		dispatch(getSpecialGroupListRequest());
		try {
			const response = await profileApi.getSpecialGroupListService(postData);
			const data = response && response.data;

			await dispatch(getSpecialGroupListSuccess(data));
		} catch (error) {
			if (error && error.response && error.response.data && error.response.data.message) {
				toast.error(error.response.data.message.toString());
			}

			await dispatch(getSpecialGroupListFailure());
		}
	};
};
