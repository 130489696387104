import * as actionTypes from '../constants/actionTypes';

const initialState = {
	gameType: {
		loading: false,
		data: null,
	},
	gameConditionType: {
		loading: false,
		data: null,
	},
	games: {
		loading: false,
		data: null,
	},
	updateGame: {
		loading: false,
		data: null,
	},
	badges: {
		loading: false,
		data: null,
	},
	postBadge: {
		loading: false,
		data: null,
	},
	putBadge: {
		loading: false,
		data: null,
	},
	deleteBadge: {
		loading: false,
		data: null,
	},
};

const Game = (state = initialState, { type, payload }) => {
	switch (type) {
		case actionTypes.GET_ALL_GAME_TYPE_REQUEST:
			return {
				...state,
				gameType: {
					...state.gameType,
					loading: true,
				},
			};
		case actionTypes.GET_ALL_GAME_TYPE_SUCCESS:
			return {
				...state,
				gameType: {
					loading: false,
					data: payload,
				},
			};
		case actionTypes.GET_ALL_GAME_TYPE_FAILURE:
			return {
				...state,
				gameType: {
					loading: false,
					data: null,
				},
			};

		case actionTypes.GET_ALL_GAME_CONDITION_TYPE_REQUEST:
			return {
				...state,
				gameConditionType: {
					...state.gameConditionType,
					loading: true,
				},
			};
		case actionTypes.GET_ALL_GAME_CONDITION_TYPE_SUCCESS:
			return {
				...state,
				gameConditionType: {
					loading: false,
					data: payload,
				},
			};
		case actionTypes.GET_ALL_GAME_CONDITION_TYPE_FAILURE:
			return {
				...state,
				gameConditionType: {
					loading: false,
					data: null,
				},
			};

		case actionTypes.GET_ALL_GAME_REQUEST:
			return {
				...state,
				games: {
					...state.games,
					loading: true,
				},
			};
		case actionTypes.GET_ALL_GAME_SUCCESS:
			return {
				...state,
				games: {
					loading: false,
					data: payload,
				},
			};
		case actionTypes.GET_ALL_GAME_FAILURE:
			return {
				...state,
				games: {
					loading: false,
					data: null,
				},
			};

		case actionTypes.PUT_ALL_GAME_REQUEST:
			return {
				...state,
				updateGame: {
					...state.updateGame,
					loading: true,
				},
			};
		case actionTypes.PUT_ALL_GAME_SUCCESS:
			return {
				...state,
				updateGame: {
					loading: false,
					data: payload,
				},
			};
		case actionTypes.PUT_ALL_GAME_FAILURE:
			return {
				...state,
				updateGame: {
					loading: false,
					data: null,
				},
			};

		case actionTypes.GET_BADGES_REQUEST:
			return {
				...state,
				badges: {
					...state.badges,
					loading: true,
				},
			};
		case actionTypes.GET_BADGES_SUCCESS:
			return {
				...state,
				badges: {
					loading: false,
					data: payload,
				},
			};
		case actionTypes.GET_BADGES_FAILURE:
			return {
				...state,
				badges: {
					loading: false,
					data: null,
				},
			};

		case actionTypes.POST_BADGE_REQUEST:
			return {
				...state,
				postBadge: {
					...state.postBadge,
					loading: true,
				},
			};
		case actionTypes.POST_BADGE_SUCCESS:
			return {
				...state,
				postBadge: {
					loading: false,
					data: payload,
				},
			};
		case actionTypes.POST_BADGE_FAILURE:
			return {
				...state,
				postBadge: {
					loading: false,
					data: null,
				},
			};

		case actionTypes.PUT_BADGE_REQUEST:
			return {
				...state,
				putBadge: {
					...state.putBadge,
					loading: true,
				},
			};
		case actionTypes.PUT_BADGE_SUCCESS:
			return {
				...state,
				putBadge: {
					loading: false,
					data: payload,
				},
			};
		case actionTypes.PUT_BADGE_FAILURE:
			return {
				...state,
				putBadge: {
					loading: false,
					data: null,
				},
			};

		case actionTypes.DELETE_BADGE_REQUEST:
			return {
				...state,
				deleteBadge: {
					...state.deleteBadge,
					loading: true,
				},
			};
		case actionTypes.DELETE_BADGE_SUCCESS:
			return {
				...state,
				deleteBadge: {
					loading: false,
					data: payload,
				},
			};
		case actionTypes.DELETE_BADGE_FAILURE:
			return {
				...state,
				deleteBadge: {
					loading: false,
					data: null,
				},
			};

		default:
			return state;
	}
};

export default Game;
