import * as actionTypes from '../constants/actionTypes';

const initialState = {
	numericMetrics: {
		loading: false,
		data: null,
	},
	newStudentsMetrics: {
		loading: false,
		data: null,
	},
	visitorsMetrics: {
		loading: false,
		data: null,
	},
	studentsByCityMetrics: {
		loading: false,
		data: null,
	},
	byGenderMetrics: {
		loading: false,
		data: null,
	},
	bySpecialGroupsMetrics: {
		loading: false,
		data: null,
	},
	byResidencyStatusMetrics: {
		loading: false,
		data: null,
	},
	byMostTakenCoursesMetrics: {
		loading: false,
		data: null,
	},
	byMostDailyActiveUsersMetrics: {
		loading: false,
		data: null,
	},
	innerChartLinePageData: null,
};

const Metric = (state = initialState, { type, payload }) => {
	switch (type) {
		case actionTypes.GET_NUMERIC_METRICS_REQUEST:
			return {
				...state,
				numericMetrics: { ...state.numericMetrics, loading: true },
			};
		case actionTypes.GET_NUMERIC_METRICS_SUCCESS:
			return {
				...state,
				numericMetrics: { loading: false, data: payload },
			};
		case actionTypes.GET_NUMERIC_METRICS_FAILURE:
			return {
				...state,
				numericMetrics: { loading: false, data: null },
			};

		case actionTypes.GET_NEW_STUDENTS_METRICS_REQUEST:
			return {
				...state,
				newStudentsMetrics: { ...state.newStudentsMetrics, loading: true },
			};
		case actionTypes.GET_NEW_STUDENTS_METRICS_SUCCESS:
			return {
				...state,
				newStudentsMetrics: { loading: false, data: payload },
			};
		case actionTypes.GET_NEW_STUDENTS_METRICS_FAILURE:
			return {
				...state,
				newStudentsMetrics: { loading: false, data: null },
			};

		case actionTypes.GET_VISITORS_METRICS_REQUEST:
			return {
				...state,
				visitorsMetrics: { ...state.visitorsMetrics, loading: true },
			};
		case actionTypes.GET_VISITORS_METRICS_SUCCESS:
			return {
				...state,
				visitorsMetrics: { loading: false, data: payload },
			};
		case actionTypes.GET_VISITORS_METRICS_FAILURE:
			return {
				...state,
				visitorsMetrics: { loading: false, data: null },
			};

		case actionTypes.GET_STUDENTS_METRICS_BY_CITY_REQUEST:
			return {
				...state,
				studentsByCityMetrics: { ...state.studentsByCityMetrics, loading: true },
			};
		case actionTypes.GET_STUDENTS_METRICS_BY_CITY_SUCCESS:
			return {
				...state,
				studentsByCityMetrics: { loading: false, data: payload },
			};
		case actionTypes.GET_STUDENTS_METRICS_BY_CITY_FAILURE:
			return {
				...state,
				studentsByCityMetrics: { loading: false, data: null },
			};

		case actionTypes.GET_METRICS_BY_GENDER_REQUEST:
			return {
				...state,
				byGenderMetrics: { ...state.byGenderMetrics, loading: true },
			};
		case actionTypes.GET_METRICS_BY_GENDER_SUCCESS:
			return {
				...state,
				byGenderMetrics: { loading: false, data: payload },
			};
		case actionTypes.GET_METRICS_BY_GENDER_FAILURE:
			return {
				...state,
				byGenderMetrics: { loading: false, data: null },
			};

		case actionTypes.GET_METRICS_BY_SPECIAL_GROUPS_REQUEST:
			return {
				...state,
				bySpecialGroupsMetrics: { ...state.bySpecialGroupsMetrics, loading: true },
			};
		case actionTypes.GET_METRICS_BY_SPECIAL_GROUPS_SUCCESS:
			return {
				...state,
				bySpecialGroupsMetrics: { loading: false, data: payload },
			};
		case actionTypes.GET_METRICS_BY_SPECIAL_GROUPS_FAILURE:
			return {
				...state,
				bySpecialGroupsMetrics: { loading: false, data: null },
			};

		case actionTypes.GET_METRICS_BY_RESIDENCY_STATUS_REQUEST:
			return {
				...state,
				byResidencyStatusMetrics: { ...state.byResidencyStatusMetrics, loading: true },
			};
		case actionTypes.GET_METRICS_BY_RESIDENCY_STATUS_SUCCESS:
			return {
				...state,
				byResidencyStatusMetrics: { loading: false, data: payload },
			};
		case actionTypes.GET_METRICS_BY_RESIDENCY_STATUS_FAILURE:
			return {
				...state,
				byResidencyStatusMetrics: { loading: false, data: null },
			};

		case actionTypes.GET_MOST_TAKEN_COURSES_METRICS_REQUEST:
			return {
				...state,
				byMostTakenCoursesMetrics: { ...state.byMostTakenCoursesMetrics, loading: true },
			};
		case actionTypes.GET_MOST_TAKEN_COURSES_METRICS_SUCCESS:
			return {
				...state,
				byMostTakenCoursesMetrics: { loading: false, data: payload },
			};
		case actionTypes.GET_MOST_TAKEN_COURSES_METRICS_FAILURE:
			return {
				...state,
				byMostTakenCoursesMetrics: { loading: false, data: null },
			};

		case actionTypes.GET_MOST_DAILY_ACTIVE_USERS_METRICS_REQUEST:
			return {
				...state,
				byMostDailyActiveUsersMetrics: { ...state.byMostDailyActiveUsersMetrics, loading: true },
			};
		case actionTypes.GET_MOST_DAILY_ACTIVE_USERS_METRICS_SUCCESS:
			return {
				...state,
				byMostDailyActiveUsersMetrics: { loading: false, data: payload },
			};
		case actionTypes.GET_MOST_DAILY_ACTIVE_USERS_METRICS_FAILURE:
			return {
				...state,
				byMostDailyActiveUsersMetrics: { loading: false, data: null },
			};

		case actionTypes.SET_INNER_CHART_LINE_PAGE_DATA:
			return {
				...state,
				innerChartLinePageData: payload,
			};

		default:
			return state;
	}
};

export default Metric;
