import * as actionTypes from '../constants/actionTypes';

const initialState = {
	allSubjects: {
		loading: false,
		data: null,
		allData: null,
	},
	postSubject: {
		loading: false,
		data: null,
	},
	putSubject: {
		loading: false,
		data: null,
	},
	deleteSubject: {
		loading: false,
		data: null,
	},
	subjectById: {
		loading: false,
		data: null,
	},
};

const Subject = (state = initialState, { type, payload }) => {
	switch (type) {
		case actionTypes.GET_ALL_SUBJECTS_REQUEST:
			return {
				...state,
				allSubjects: {
					...state.allSubjects,
					loading: true,
				},
			};
		case actionTypes.GET_ALL_SUBJECTS_SUCCESS:
			const mergeSubjectsByChildren = payload => {
				let subjects = [];
				for (let i of payload) {
					const children = i.children;

					subjects = [...subjects, ...children, i];
				}

				return subjects.length > 0 && subjects.sort((a, b) => a.id - b.id);
			};
			return {
				...state,
				allSubjects: {
					loading: false,
					data: payload,
					allData: mergeSubjectsByChildren(payload),
				},
			};
		case actionTypes.GET_ALL_SUBJECTS_FAILURE:
			return {
				...state,
				allSubjects: {
					loading: false,
					data: null,
					allData: null,
				},
			};

		case actionTypes.POST_SUBJECT_REQUEST:
			return {
				...state,
				postSubject: {
					...state.postSubject,
					loading: true,
				},
			};
		case actionTypes.POST_SUBJECT_SUCCESS:
			return {
				...state,
				postSubject: {
					loading: false,
					data: payload,
				},
			};
		case actionTypes.POST_SUBJECT_FAILURE:
			return {
				...state,
				postSubject: {
					loading: false,
					data: null,
				},
			};

		case actionTypes.PUT_SUBJECT_REQUEST:
			return {
				...state,
				putSubject: {
					...state.putSubject,
					loading: true,
				},
			};
		case actionTypes.PUT_SUBJECT_SUCCESS:
			return {
				...state,
				putSubject: {
					loading: false,
					data: payload,
				},
			};
		case actionTypes.PUT_SUBJECT_FAILURE:
			return {
				...state,
				putSubject: {
					loading: false,
					data: null,
				},
			};

		case actionTypes.DELETE_SUBJECT_REQUEST:
			return {
				...state,
				deleteSubject: {
					...state.deleteSubject,
					loading: true,
				},
			};
		case actionTypes.DELETE_SUBJECT_SUCCESS:
			return {
				...state,
				deleteSubject: {
					loading: false,
					data: payload,
				},
			};
		case actionTypes.DELETE_SUBJECT_FAILURE:
			return {
				...state,
				deleteSubject: {
					loading: false,
					data: null,
				},
			};

		case actionTypes.GET_SUBJECT_BY_ID_REQUEST:
			return {
				...state,
				subjectById: {
					...state.subjectById,
					loading: true,
				},
			};
		case actionTypes.GET_SUBJECT_BY_ID_SUCCESS:
			return {
				...state,
				subjectById: {
					loading: false,
					data: payload,
				},
			};
		case actionTypes.GET_SUBJECT_BY_ID_FAILURE:
			return {
				...state,
				subjectById: {
					loading: false,
					data: null,
				},
			};

		default:
			return state;
	}
};

export default Subject;
